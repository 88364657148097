import React from "react";

const SectionTitle = (props) => {
  return (
    <div
      className="section-title"
      style={{ paddingLeft: props.paddLeft ? 20 : 0 }}
    >
      <div className="section-title__left">
        <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
        <h5>{props.caption}</h5>
      </div>
      {props.action && (
        <div
          className="section-title__actions"
          onClick={() => (props.onClick ? props.onClick() : null)}
        >
          <span>{props.actionText}</span>
        </div>
      )}
    </div>
  );
};

export default SectionTitle;

import React from "react";
import Footer from "../../../components/web/Footer";
import TopBarWeb from "../../../components/web/topBar";
import { isMobile } from "react-device-detect";
import TopBarMobile from "../topBarMobile";

const MainLayout = (props) => {
  return (
    <div className="main-layout" style={{ marginTop: props.margin ? 140 : 0 }}>
      <div style={{ marginBottom: 50, minHeight: "75vh" }}>
        {props.children}
      </div>

      <Footer></Footer>
    </div>
  );
};

export default MainLayout;

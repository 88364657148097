import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../components/web/Layout/MainLayout";
import SectionTitle from "../../../components/web/sectionTitle";
import filePath from "../../../constants/pathImages";
import { AiOutlineDelete } from "react-icons/ai";
import useCarrito from "../../../hooks/useCarrito";
import NumberFormat from "react-number-format";
import { FiShoppingCart } from "react-icons/fi";
import APICLASS from "../../../api/web";
import useCliente from "../../../hooks/useCliente";
import color from "../../../constants/color";
import useFecha from "../../../hooks/useFecha";
import {
  Tooltip,
  Whisper,
  Drawer,
  Avatar,
  Tag,
  IconButton,
  Alert,
  Input,
  Icon,
} from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import Empty from "../../../components/web/Empty";
import { Badge } from "rsuite";

const API = new APICLASS();

const OrdersAccount = (props) => {
  const { Column, HeaderCell, Cell, Pagination } = Table;
  const history = useHistory();
  const cliente = useCliente();
  const fecha = useFecha();

  const [loader, setLoader] = useStateIfMounted(true);
  const [compras, setCompras] = useState([]);
  const [section, setSection] = useState("list");
  const [comentariosDrawer, setComentariosDrawer] = useState(false);
  const [mensajes, setMensajes] = useStateIfMounted(false);
  const [mensaje, setMensaje] = useStateIfMounted("");

  const [togle, setTogle] = useStateIfMounted(false);
  const [ordenSeleccionada, setOrdenSeleccionada] = useStateIfMounted(false);

  useEffect(() => {
    _getCompras();
  }, []);

  const _getCompras = async () => {
    setLoader(true);
    const { data } = await API.getComprasCliente(cliente._data().id);
    if (data !== false && Array.isArray(data)) {
      setCompras(data);
    }
    setLoader(false);
  };

  const _seleccionarVenta = (item) => {
    setOrdenSeleccionada(item);
    setMensajes(item.mensajes);
    setSection("detail");
  };

  const _saveMensaje = async () => {
    let request = {
      mensaje: mensaje,
      id_venta: ordenSeleccionada.venta.id,
      id_cliente: ordenSeleccionada.venta.id_cliente,
    };

    const { data } = await API.saveMensaje(request);

    if (data.status === true) {
      Alert.success("Mensaje enviado.");
      setComentariosDrawer(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      Alert.error("Hubo un error al enviar el mensaje.");
    }
  };

  return (
    <div>
      <Drawer
        show={comentariosDrawer}
        onHide={() => setComentariosDrawer(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Comments</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {/* Mensajes */}
          <div className="mensajes">
            <div>
              {mensajes !== false ? (
                <div>
                  {Array.isArray(mensajes) && mensajes.length > 0 ? (
                    <div>
                      {mensajes.map((item, index) => {
                        return (
                          <div
                            className="mensaje"
                            key={`mensajes-${index}`}
                            style={{
                              background:
                                item.id_cliente == null
                                  ? color.mainLight
                                  : "#939393",
                              padding: 5,
                            }}
                          >
                            <div className="mensaje__top">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item.id_cliente != null ? (
                                  <h5
                                    style={{ color: color.text }}
                                  >{`${item.nombre} ${item.apellidos}`}</h5>
                                ) : (
                                  <h5
                                    style={{ color: color.accent }}
                                  >{`DMX PERFORMANCE`}</h5>
                                )}

                                <span style={{ marginLeft: 10 }}>
                                  {fecha.timeAgo(item.created_at)}
                                </span>
                              </div>
                            </div>
                            <div className="mensaje__texto">
                              <p>{item.mensaje}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Empty>
                      No pudimos encontrar los mensajes de esta venta
                    </Empty>
                  )}
                </div>
              ) : (
                <div>
                  <Empty>No se han agregado mensajes a la venta</Empty>
                </div>
              )}
            </div>
          </div>
          {/* /Mensajes */}
        </Drawer.Body>
        <Drawer.Footer>
          <div
            className="input__item"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 40,
              marginBottom: 100,
            }}
          >
            <div style={{ flex: 1 }}>
              <Input
                value={mensaje}
                placeholder="Escribe tu mensaje"
                onChange={(val) => setMensaje(val)}
                size="lg"
              />
            </div>

            <div style={{ marginLeft: 20 }}>
              <IconButton
                size="lg"
                onClick={() => _saveMensaje()}
                icon={<Icon icon="send" />}
                color="primary"
              />
            </div>
          </div>
        </Drawer.Footer>
      </Drawer>

      {section === "list" ? (
        // =========== LISTA ==========
        <div>
          <SectionTitle
            title={"My Orders"}
            caption={"Historial"}
          ></SectionTitle>

          <div>
            {compras.length > 0 ? (
              <div>
                <Table
                  style={{
                    background: "#101010",
                    borderRadius: 20,
                    color: "white",
                  }}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>Date</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compras.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.venta.uid}</td>
                          <td>{item.venta.created_at}</td>

                          <td>${item.venta.total}</td>
                          <td>{item.venta.status}</td>
                          <td>
                            <Button
                              onClick={() => _seleccionarVenta(item)}
                              variant="light"
                              size="sm"
                            >
                              DETAILS
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="empty">
                <FiShoppingCart size={60}></FiShoppingCart>
                <span>You have not Orders yet</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        // =========== DETALLE ==========
        <div>
          <div
            style={{
              fontSize: 24,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setSection("list")}
            >
              x
            </span>
          </div>
          <div className="venta">
            <div className="venta__header">
              <h3>#{ordenSeleccionada.venta.uid}</h3>
              <Tag color={ordenSeleccionada.venta.status ? "orange" : "green"}>
                {ordenSeleccionada.venta.status}
              </Tag>
            </div>

            <Button
              onClick={() => setComentariosDrawer(true)}
              variant="light"
              size="sm"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              SEE COMMENTS
            </Button>

            <div className="venta__info">
              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Customer</h4>
                  <div>
                    <Table
                      style={{
                        background: "#101010",
                        borderRadius: 20,
                        color: "white",
                      }}
                      striped
                      bordered
                      hover
                      responsive
                    >
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{ordenSeleccionada.venta.nombre}</td>
                          <td>{ordenSeleccionada.venta.apellidos}</td>
                          <td>{ordenSeleccionada.venta.email}</td>
                          <td>{ordenSeleccionada.venta.telefono}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* /item */}

              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Shipping</h4>
                  <div>
                    <Table
                      style={{
                        background: "#101010",
                        borderRadius: 20,
                        color: "white",
                      }}
                      striped
                      bordered
                      hover
                      responsive
                    >
                      <thead>
                        <tr>
                          <th>Street Adress</th>
                          <th>Apartment, Suite, Unit, etc.</th>
                          <th>Coutry</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Post Code / ZIP</th>
                          <th>Shipping Guide</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{ordenSeleccionada.venta.direccion_calle}</td>
                          <td>{ordenSeleccionada.venta.direccion_extra}</td>
                          <td>{ordenSeleccionada.venta.pais}</td>
                          <td>{ordenSeleccionada.venta.estado}</td>
                          <td>{ordenSeleccionada.venta.ciudad}</td>
                          <td>{ordenSeleccionada.venta.zip}</td>
                          <td>{ordenSeleccionada.venta.numero_guia}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* /item */}

              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Products</h4>
                  {Array.isArray(ordenSeleccionada.productos) &&
                  ordenSeleccionada.productos.length > 0 ? (
                    <>
                      <div>
                        <Table
                          style={{
                            background: "#101010",
                            borderRadius: 20,
                            color: "white",
                          }}
                          striped
                          bordered
                          hover
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Price offer</th>
                              <th>Quantity</th>
                              <th>Color</th>
                              <th>Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordenSeleccionada.productos.map((item, index) => {
                              return (
                                <tr>
                                  <td>{item.nombre}</td>
                                  <td>${item.precio}</td>
                                  <td>
                                    {item.precio_oferta === null
                                      ? ""
                                      : `$${item.precio_oferta}`}
                                  </td>
                                  <td>{item.cantidad}</td>
                                  <td>{item.color}</td>
                                  <td>{item.comments}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <>Empty products</>
                  )}
                </div>
              </div>
              {/* /item */}

              {/* TOTALS */}
              <div className="venta__totals">
                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">Sub Total</div>
                  <div className="venta__totals__item__text">
                    ${ordenSeleccionada.venta.subtotal}
                  </div>
                </div>
                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">Shipping</div>
                  <div className="venta__totals__item__text">
                    ${ordenSeleccionada.venta.shipping_total}
                  </div>
                </div>
                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">TAX</div>
                  <div className="venta__totals__item__text">
                    ${ordenSeleccionada.venta.impuestos_total}
                  </div>
                </div>

                {ordenSeleccionada.venta.descuento_seller != "0.00" ||
                  ordenSeleccionada.venta.descuento_seller != null ||
                  (ordenSeleccionada.venta.descuento_seller != "" && (
                    <div className="venta__totals__item">
                      <div className="venta__totals__item__caption">
                        Reseller Discount
                      </div>
                      <div
                        className="venta__totals__item__text"
                        style={{ color: "red" }}
                      >
                        -${ordenSeleccionada.venta.descuento_seller_total}
                      </div>
                    </div>
                  ))}

                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">TOTAL</div>
                  <div className="venta__totals__item__text venta__totals__item__grand-total">
                    ${ordenSeleccionada.venta.total}
                  </div>
                </div>
              </div>
              {/* /TOTALS */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersAccount;

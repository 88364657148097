import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import imagePath from "../../../constants/pathImages";
import ImageDefault from "../../../assets/img/default.jpg";

const ProductRow = (props) => {
  const history = useHistory();

  const _navigate = (slug, id) => {
    history.push(`/product/${slug}/${id}`);
  };
  return (
    <div
      className="product-row"
      onClick={() => _navigate(props.slug, props.id)}
    >
      {props.image === false || undefined ? (
        <div
          className="product-row__image"
          style={{
            backgroundImage: `url(${ImageDefault})`,
          }}
        ></div>
      ) : (
        <div
          className="product-row__image"
          style={{
            backgroundImage: `url(${imagePath + props.image})`,
          }}
        ></div>
      )}

      <div className="product-row__info">
        <div
          className="product-row__info__name"
          onClick={() => _navigate(props.slug, props.id)}
        >
          <span>{props.name}</span>
        </div>

        <div className="product-row__info__price">
          {props.priceSale !== "null" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="product-row__info__price__regular--whithOffer">
                <span>${props.priceRegular} USD</span>
              </div>
              <div className="product-row__info__price__sale">
                <span>${props.priceSale} USD</span>
              </div>
            </div>
          ) : (
            <div>
              <div className="product-row__info__price__regular">
                <span>${props.priceRegular} USD</span>
              </div>
            </div>
          )}
        </div>
        <div className="product-row__actions">
          <Button onClick={() => _navigate(props.slug, props.id)} size="sm">
            SHOP NOW
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductRow;

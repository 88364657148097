import React, { useEffect } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import MainLayout from "../../../components/web/Layout/MainLayout";
import SectionTitle from "../../../components/web/sectionTitle";
import Vehicle from "../../../components/web/Vehicle";
import { Tag } from "rsuite";
import SideBarPolaris from "./sidebar";
import _ from "lodash";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import Loader from "../../../components/web/Loader";
import {
  SideBar,
  SidebarDivider,
  SidebarTitle,
  SidebarList,
} from "../../../components/web/SideBar";
const API = new APICLASS();

const CanamPage = (props) => {
  const [vehiculos, setVehiculos] = useStateIfMounted([]);
  const [vehiculosFiltrados, setVehiculosFiltrados] = useStateIfMounted([]);
  const [plazasFiltro, setPlazasFiltro] = useStateIfMounted(false);

  const [loader, setLoader] = useStateIfMounted(true);

  useEffect(() => {
    _getVehiculos();
  }, []);

  const _getVehiculos = async () => {
    const { data } = await API.getVehiculos(2);
    if (data !== false) {
      setVehiculos(data);
      setVehiculosFiltrados(data);
    }
    setLoader(false);
  };

  const _filtrarPorPlazas = (val) => {
    setPlazasFiltro(val);
    const filtrados = _.filter(vehiculos, ["plazas", val.value]);
    setVehiculosFiltrados(filtrados);
  };

  const _removeFilter = () => {
    setPlazasFiltro(false);
    setVehiculosFiltrados(vehiculos);
  };

  const VehiculosList = () => {
    return (
      <div>
        {plazasFiltro !== false && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tag onClose={() => _removeFilter()} closable>
              {plazasFiltro.nombre}
            </Tag>
          </div>
        )}
        <SectionTitle title="Canam" caption="Models"></SectionTitle>
        {vehiculosFiltrados.length > 0 ? (
          <div className="animate__animated animate__fadeIn">
            <div className="grid-flex">
              {vehiculosFiltrados.map((item, index) => {
                return (
                  <Vehicle
                    key={index}
                    image={item.imagen}
                    name={item.nombre}
                    brand={item.marca}
                    id_categoria={item.id_categoria}
                    plazas={item.plazas}
                    id={item.id}
                    slug={item.slug}
                  ></Vehicle>
                );
              })}
            </div>
          </div>
        ) : (
          <div>vacio</div>
        )}
      </div>
    );
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={3}>
              <SideBar>
                <SidebarTitle title="Filter by seats"></SidebarTitle>

                <SidebarList
                  actionItem={true}
                  actionItemFunction={_filtrarPorPlazas}
                  data={[
                    { id: 1, value: "2", nombre: "2 Seats" },
                    { id: 2, value: "4", nombre: "4 Seats" },
                  ]}
                  categoriaActiva={plazasFiltro}
                ></SidebarList>
              </SideBar>
            </Col>
            <Col xs={12} md={12} lg={9}>
              {loader ? <Loader></Loader> : <VehiculosList></VehiculosList>}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default CanamPage;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Alert, Checkbox, Input, SelectPicker } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import APIWORLDCLASS from "../../../api/world";

import SectionTitle from "../../../components/web/sectionTitle";
import useCliente from "../../../hooks/useCliente";
import color from "../../../constants/color";
import Loading from "../../../components/web/Loading";
const API = new APICLASS();
const APIWORLD = new APIWORLDCLASS();

const Adress = (props) => {
  const cliente = useCliente();
  const history = useHistory();

  const [company, setCompany] = useStateIfMounted(
    cliente._data().empresa === null ? "" : cliente._data().empresa
  );
  const [street, setStreet] = useStateIfMounted(
    cliente._data().direccion_calle === null
      ? ""
      : cliente._data().direccion_calle
  );
  const [cityID, setCityID] = useStateIfMounted(
    cliente._data().id_ciudad === null
      ? ""
      : {
          id: cliente._data().id_ciudad,
          name: cliente._data().ciudad,
        }
  );
  const [stateID, setStateID] = useStateIfMounted(
    cliente._data().id_state === "0"
      ? ""
      : {
          id: cliente._data().id_state,
          name: cliente._data().estado,
        }
  );

  const [zip, setZip] = useStateIfMounted(
    cliente._data().zip === "0" ? "" : cliente._data().zip
  );
  const [countryID, setCountryID] = useStateIfMounted(
    cliente._data().id_pais === "0"
      ? ""
      : {
          id: cliente._data().id_pais,
          name: cliente._data().pais,
        }
  );

  const [ciudad, setCiudad] = useStateIfMounted(
    cliente._data().ciudad === null ? "" : cliente._data().ciudad
  );

  const [pais, setPais] = useStateIfMounted(
    cliente._data().pais === null ? "" : cliente._data().pais
  );

  const [estado, setEstado] = useStateIfMounted(
    cliente._data().estado === null ? "" : cliente._data().estado
  );

  const [paises, setPaises] = useStateIfMounted([]);
  const [estados, setEstados] = useStateIfMounted([]);
  const [ciudades, setCiudades] = useStateIfMounted([]);

  const [phone, setPhone] = useStateIfMounted(
    cliente._data().telefono === null ? "" : cliente._data().telefono
  );
  const [adressXtra, setAdressXtra] = useStateIfMounted(
    cliente._data().direccion_extra === null
      ? ""
      : cliente._data().direccion_extra
  );
  const [loader, setLoader] = useStateIfMounted("");
  const [loaderPaises, setLoaderPaises] = useStateIfMounted(true);

  const [error, setError] = useStateIfMounted(false);

  const _update = async () => {
    setLoader(true);
    setError(false);
    const request = {
      street: street === "" ? null : street,
      company: company === "" ? null : company,
      cityID: cityID === "" ? null : cityID.id,
      stateID: stateID === "" ? null : stateID.id,
      zip: zip === "" ? null : zip,
      countryID: countryID === "" ? null : countryID.id,
      phone: phone === "" ? null : phone,
      adressXtra: adressXtra === "" ? null : adressXtra,
      estado: estado,
      ciudad: ciudad,
      pais: pais,
      id_cliente: cliente._data().id,
    };

    const { data } = await API.UpdateClienteDireccion(request);

    if (!data.error) {
      Alert.success("Account updated successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      Alert.error("Error ocurred, please try later.");
    }
    setLoader(false);
  };

  useEffect(() => {
    _getPaises();
  }, []);

  const _getPaises = async () => {
    const { data } = await APIWORLD.getPaises();
    if (data !== false) {
      const paisesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        paisesArr.push(itemData);
      });

      setPaises(paisesArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (countryID !== false || countryID !== null) {
      _getEstados();
    }
  }, [countryID]);

  const _getEstados = async () => {
    setLoaderPaises(true);
    setEstados([]);
    setCiudades([]);
    const { data } = await APIWORLD.getEstadosDelPais(countryID.id);
    if (data !== false) {
      const estadosArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { id: item.id, name: item.name },
        };

        estadosArr.push(itemData);
      });

      setEstados(estadosArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (stateID !== false || stateID !== null) {
      _getCiudades();
    }
  }, [stateID]);

  const _getCiudades = async () => {
    setLoaderPaises(true);
    const { data } = await APIWORLD.getCiudadesDelEstado(stateID.id);
    if (data !== false) {
      const ciudadesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        ciudadesArr.push(itemData);
      });

      setCiudades(ciudadesArr);
    }
    setLoaderPaises(false);
  };

  const _setPais = (item) => {
    setPais(item.name);
    setCountryID(item);
  };

  const _setCiudad = (item) => {
    setCiudad(item.name);
    setCityID(item);
  };

  const _setEstado = (item) => {
    setEstado(item.name);
    setStateID(item);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="animate__animated animate__fadeIn">
              <SectionTitle
                title={"My adress"}
                caption={"We will use this address for your shipments"}
              ></SectionTitle>

              {loaderPaises ? (
                <Loading text="Loading data..."></Loading>
              ) : (
                <div className="form-box form-box--transparent">
                  <Row>
                    <Col xs={12}>
                      <Form>
                        <Row>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Phone number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                              <Input
                                value={phone}
                                onChange={(val) => setPhone(val)}
                                placeholder="Phone number"
                              ></Input>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Country <span style={{ color: "red" }}>*</span>
                              </span>
                              <SelectPicker
                                searchable
                                data={paises}
                                appearance="default"
                                placeholder="Select the coutry"
                                cleanable={false}
                                value={countryID}
                                onChange={(val) => _setPais(val)}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                State <span style={{ color: "red" }}>*</span>
                              </span>
                              <SelectPicker
                                data={estados}
                                appearance="default"
                                placeholder="Select the stateID"
                                cleanable={false}
                                value={stateID}
                                onChange={(val) => _setEstado(val)}
                                searchable={true}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Town/City
                              </span>
                              <SelectPicker
                                searchable
                                data={ciudades}
                                appearance="default"
                                placeholder="Select the Town/City"
                                cleanable={false}
                                value={cityID}
                                onChange={(val) => _setCiudad(val)}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Street Adress{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                              <Input
                                value={street}
                                onChange={(val) => setStreet(val)}
                                placeholder="Street adress"
                              ></Input>
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Apartment, Suite, Unit, etc.
                              </span>
                              <Input
                                value={adressXtra}
                                onChange={(val) => setAdressXtra(val)}
                                placeholder="Extra info adress"
                              ></Input>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={6}>
                            <div className="form__item">
                              <span className="form__item__label">
                                Post Code / ZIP{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                              <Input
                                value={zip}
                                onChange={(val) => setZip(val)}
                                placeholder="Post code / zip"
                              ></Input>
                            </div>
                          </Col>
                        </Row>

                        <Button
                          style={{ marginTop: 20 }}
                          disabled={loader}
                          onClick={() => _update()}
                        >
                          {loader ? "Loading…" : "SAVE"}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Adress;

import React, { useCallback, useContext, useEffect } from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import StoreState from "../../../state/store";
import { Button } from "react-bootstrap";
import color from "../../../constants/color";
import Avatar from "react-avatar";
import Logo from "../../../assets/img/logo.png";
import utvIcon from "../../../assets/img/utv-icon-accent.png";
import { useStateIfMounted } from "use-state-if-mounted";
import { Drawer, FlexboxGrid, Alert } from "rsuite";
import APICLASS from "../../../api/web";
import useCarrito from "../../../hooks/useCarrito";
import DrawerVehiculos from "../DrawerVehiculos";
import NumberFormat from "react-number-format";
import useNavigation from "../../../hooks/useNavigation";
import imagePath from "../../../constants/pathImages";
import { IoMenu, IoSearch } from "react-icons/io5";

const API = new APICLASS();

const TopBarMobile = (props) => {
  const navigation = useNavigation();
  const match = navigation._getMatch();

  const carrito = useCarrito();
  const {
    cliente,
    setCliente,
    vehiculos,
    setVehiculos,
    vehiculoSeleccionado,
    setVehiculoSeleccionado,
    drawerVehiculosToggle,
    setDrawerVehiculosToggle,
  } = useContext(StoreState);

  const [productosSearch, setProductosSearch] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");
  const [showSearch, setShowSearch] = useStateIfMounted(false);

  const links = [
    { link: "/", label: "HOME", routeName: "home" },
    { link: "/categories", label: "CATEGORIES", routeName: "categories" },
    { link: "/polaris", label: "POLARIS", routeName: "polaris" },
    { link: "/canam", label: "CANAM", routeName: "canam" },
    { link: "/contact", label: "CONTACT", routeName: "contact" },
  ];

  useEffect(() => {
    if (search.length > 0) {
      setShowSearch(true);
      _search();
    } else {
      setShowSearch(false);
      setProductosSearch([]);
    }
  }, [search]);

  const _search = async () => {
    const { data } = await API.getProductosSearch(search);
    if (data !== false) {
      if (Array.isArray(data)) {
        setProductosSearch(data);
      }
    } else {
      setProductosSearch([]);
    }
  };

  const _goProduct = (item) => {
    setShowSearch(false);
    setSearch("");
    navigation._navigate(`/product/${item.producto.slug}/${item.producto.id}`);
  };

  const _removeVehiculoSeleccionado = () => {
    setVehiculoSeleccionado(false);
    localStorage.removeItem("selectedVehicle");
    Alert.success(`Selected vehicle removed`);
  };

  const UserTop = useCallback(() => {
    return (
      <div
        className="top-bar-web__user"
        onClick={() => navigation._navigate("/account/my-account")}
      >
        {cliente !== false && (
          <Avatar
            round
            name={`${cliente.nombre} ${cliente.apellidos}`}
            size="30"
          />
        )}

        <span className="top-bar-web__user__name">
          {`${cliente.nombre} ${cliente.apellidos}`}
        </span>
      </div>
    );
  }, [cliente]);

  const Cliente = (props) => {
    if (cliente === false) {
      //No logueado
      return (
        <div className="top-bar-web__no-user">
          <Button
            onClick={() => navigation._navigate("/login")}
            size="md"
            variant="link"
          >
            My account
          </Button>
        </div>
      );
    } else {
      return <UserTop></UserTop>;
    }
  };

  return (
    <div>
      <DrawerVehiculos></DrawerVehiculos>
      {/* menu mobile */}
      <div className="menu-mobile">
        <div className="menu-mobile__toggle">
          <IoMenu></IoMenu>
        </div>
        <div className="menu-mobile__logo">
          <img src={Logo}></img>
        </div>
        <div className="menu-mobile__search">
          <IoSearch></IoSearch>
        </div>
      </div>
      {/* /menu mobile */}

      {/* vehiculo seleccionado */}
      <div className="menu-mobile__vehiculo">
        {vehiculoSeleccionado === false ? (
          <div
            className="menu-mobile__vehiculo__selected__false"
            onClick={() => setDrawerVehiculosToggle(true)}
          >
            <img src={utvIcon}></img>
            <span>Select your vehicle</span>
          </div>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="menu-mobile__vehiculo__selected__false"
          >
            <div onClick={() => setDrawerVehiculosToggle(true)}>
              <img src={utvIcon}></img>
              <span>
                {vehiculoSeleccionado.nombre.length > 25
                  ? `${vehiculoSeleccionado.nombre.substring(0, 25)}...`
                  : vehiculoSeleccionado.nombre}
              </span>
            </div>

            <Button
              onClick={() => _removeVehiculoSeleccionado()}
              variant="link"
              size="sm"
              style={{
                color: "red",
                fontSize: "21px",
                position: "relative",
                top: "-3px",
              }}
            >
              x
            </Button>
          </div>
        )}
      </div>
      {/* vehiculo seleccionado */}
    </div>
  );
};

export default TopBarMobile;

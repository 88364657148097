import { ConfigProvider } from "react-avatar";
import { QueryClient, QueryClientProvider } from "react-query";
import PublicNavigation from "./navigation/public";
import { StoreProvider } from "./state/store";

const Main = (props) => {
  return <PublicNavigation navigation={props}></PublicNavigation>;
};

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <ConfigProvider colors={["#28F0D9", "#ffffff", "#fe531f"]}>
            <Main></Main>
          </ConfigProvider>
        </StoreProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;

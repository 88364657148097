import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import MainLayout from "../../../components/web/Layout/MainLayout";
import color from "../../../constants/color";
import useNavigation from "../../../hooks/useNavigation";

const CheckOutErrorPage = (props) => {
  const navigation = useNavigation();

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BsXCircle size={90} color={"red"}></BsXCircle>

                <Button
                  onClick={() => navigation._navigate("/")}
                  style={{ marginTop: 20 }}
                >
                  An unknown error occurred, please try again later
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default CheckOutErrorPage;

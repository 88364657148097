import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import pathImage from "../../../constants/pathImages";

const CategoryRow = (props) => {
  const history = useHistory();

  const _navigate = (slug, id) => {
    history.push(`/categories/${slug}/${id}`);
  };

  return (
    <div
      className="category-row"
      style={{
        backgroundImage: `url(${pathImage}${props.image})`,
        width: props.full ? "100%" : "",
      }}
    >
      <div className="category-row__content">
        <h3>{props.name}</h3>
        <Button size="sm" onClick={() => _navigate(props.slug, props.id)}>
          PRODUCTS
        </Button>
      </div>
    </div>
  );
};

export default CategoryRow;

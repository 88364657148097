import React, { useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import VehicleCliente from "../../../components/web/VehicleCliente";
import StoreContext from "../../../state/store";
import SectionTitle from "../../../components/web/sectionTitle";

const VehiculosAccount = () => {
  const {
    cliente,
    vehiculos,
    vehiculoSeleccionado,
    drawerVehiculosToggle,
    setDrawerVehiculosToggle,
    drawerVehiculosToSave,
    setDrawerVehiculosToSave,
  } = useContext(StoreContext);

  const _showDrawerVehiculos = () => {
    setDrawerVehiculosToSave(true);
    setDrawerVehiculosToggle(true);
  };

  return (
    <div>
      <SectionTitle
        title={"My Vehicles"}
        caption={""}
        action
        actionText="Add vehicule"
        onClick={() => _showDrawerVehiculos()}
      ></SectionTitle>
      <div>
        <p className="text">
          Here you can manage your vehicles to adjust our website suggestions.
          <br></br>
          Our website will give you products suggestions for added vehicles.
        </p>

        {vehiculos.length > 0 ? (
          <div className="animate__animated animate__fadeIn">
            <h2 className="mt-4 mb-2">List</h2>
            <div className="grid-flex">
              {vehiculos.map((item, index) => {
                return (
                  <VehicleCliente
                    key={index}
                    name={item.nombre}
                    brand={item.marca}
                    image={item.imagen}
                    plazas={item.plazas}
                    slug={item.slug}
                    id={item.id_vehiculo}
                    selected={
                      vehiculoSeleccionado !== false &&
                      item.id === vehiculoSeleccionado.id
                        ? true
                        : false
                    }
                  ></VehicleCliente>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VehiculosAccount;

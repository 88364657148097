import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../api/web";
const API = new APICLASS();
const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
  const [cliente, setCliente] = useStateIfMounted(false);
  const [vehiculos, setVehiculos] = useStateIfMounted(false);
  const [vehiculoSeleccionado, setVehiculoSeleccionado] =
    useStateIfMounted(false);
  const [drawerVehiculosToggle, setDrawerVehiculosToggle] =
    useStateIfMounted(false);

  const [drawerVehiculosToSave, setDrawerVehiculosToSave] =
    useStateIfMounted(false);

  const [cart, setCart] = useStateIfMounted([]);

  const [categorias, setCategorias] = useStateIfMounted([]);

  const [impuestos, setImpuestos] = useStateIfMounted(0);

  useEffect(() => {
    if (localStorage.getItem("selectedVehicle") !== null) {
      const vehiculo = JSON.parse(localStorage.getItem("selectedVehicle"));
      setVehiculoSeleccionado(vehiculo);
    }
  }, []);

  useEffect(() => {
    _getCategorias();
  }, []);

  const _getCategorias = async () => {
    const { data } = await API.getCategories();
    setCategorias(data);
  };

  useEffect(() => {
    if (localStorage.getItem("cart") !== null) {
      const cartData = JSON.parse(localStorage.getItem("cart"));
      setCart(cartData);
    }
  }, []);

  return (
    <StoreContext.Provider
      value={{
        cliente,
        setCliente,
        vehiculos,
        setVehiculos,
        vehiculoSeleccionado,
        setVehiculoSeleccionado,
        drawerVehiculosToggle,
        setDrawerVehiculosToggle,
        categorias,
        cart,
        setCart,
        drawerVehiculosToSave,
        setDrawerVehiculosToSave,
        impuestos,
        setImpuestos,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;

import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import Loader from "../../../components/web/Loader";
import PostRow from "../../../components/web/postRow";
import SectionTitle from "../../../components/web/sectionTitle";
import pathFiles from "../../../constants/pathImages";
import useNavigation from "../../../hooks/useNavigation";
import { useQuery } from "react-query";
import Loading from "../../../components/web/Loading";

const API = new APICLASS();

const Posts = () => {
  const navigaton = useNavigation();

  const _getPosts = async () => {
    const { data } = await API.getPostsTodos();
    if (Array.isArray(data)) {
      return data;
    }

    return [];
  };

  const { data: posts, isLoading, isError } = useQuery("posts", _getPosts);

  return (
    <div>
      <div className="section-wrap" style={{ marginTop: 20 }}>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <>
            {posts.length > 0 ? (
              <div>
                <SectionTitle
                  title="Customer builds"
                  caption="This is the gallery uploded by our users"
                  action
                  actionText="See more"
                  onClick={() => navigaton._navigate("/posts")}
                ></SectionTitle>

                <div className="grid-flex">
                  {posts.map((item, index) => {
                    return (
                      <PostRow
                        key={index}
                        nombre={item.cliente_nombre}
                        fecha={item.created_at}
                        item={item}
                        imagen={`${pathFiles}/${item.imagen_portada}`}
                        descripcion={item.descripcion}
                        vehiculo={item.vehiculo_nombre}
                        marca={item.marca_nombre}
                      ></PostRow>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Loader></Loader>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Posts;

import React from "react";
import Avatar from "react-avatar";
import PostImage from "../../../assets/img/post.png";
import ModalImage from "react-modal-image";
import { Drawer, Button } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Moment from "react-moment";
import moment from "moment";

const PostRow = (props) => {
  const [togle, setTogle] = useStateIfMounted(false);
  const history = useHistory();
  return (
    <div>
      <Drawer show={togle} onHide={() => setTogle(false)}>
        <Drawer.Header>
          <Drawer.Title>Post</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="drawer-post">
            <div className="drawer-post__user">
              <Avatar round name={props.nombre} size="40" />
              <span className="drawer-post__user__name">{props.nombre}</span>
            </div>
            <div className="drawer-post__caption">
              <p>{props.descripcion}</p>
              <p className="drawer-post__caption__vehiculo">
                #{props.marca} #{props.vehiculo}
              </p>
            </div>
            <img src={props.imagen}></img>
          </div>
        </Drawer.Body>
        <Drawer.Footer>
          <Button onClick={() => setTogle(false)} appearance="subtle">
            Close
          </Button>
        </Drawer.Footer>
      </Drawer>

      <div
        className="post-row"
        style={{ backgroundImage: `url(${props.imagen})` }}
        onClick={() => setTogle(true)}
      >
        <div className="post-row__tint">
          <div className="post-row__info-wrap">
            <div className="post-row__info-wrap__avatar">
              <Avatar round name={props.nombre} size="40" />
            </div>

            <div className="post-row-wrap__info">
              <span className="post-row-wrap__info__user">{props.nombre}</span>
              <span className="post-row__info__date">
                <Moment format={"dd, MMM Do YY"}>{props.fecha}</Moment>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostRow;

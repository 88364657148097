import React, { useContext, useEffect } from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import StoreState from "../../../state/store";
import { Button } from "react-bootstrap";
import color from "../../../constants/color";

import Avatar from "react-avatar";
import Logo from "../../../assets/img/logo.png";
import Vehicle from "../../../components/web/Vehicle";
import utvIcon from "../../../assets/img/utv-icon-accent.png";
import { useStateIfMounted } from "use-state-if-mounted";
import { Drawer, SelectPicker, Alert } from "rsuite";
import APICLASS from "../../../api/web";
import Loading from "../Loading";
import useCliente from "../../../hooks/useCliente";
import useNagivation from "../../../hooks/useNavigation";

const API = new APICLASS();

const DrawerVehiculos = () => {
  const navigation = useNagivation();
  const cliente = useCliente();
  const {
    drawerVehiculosToggle,
    setDrawerVehiculosToggle,
    vehiculoSeleccionado,
    setVehiculoSeleccionado,
    drawerVehiculosToSave,
    setDrawerVehiculosToSave,
  } = useContext(StoreState);

  const [polarisModelos, setPolarisModelos] = useStateIfMounted([]);
  const [canamModelos, setCanamModelos] = useStateIfMounted([]);
  const [loader, setLoader] = useStateIfMounted(true);

  const [marca, setMarca] = useStateIfMounted("canam");

  useEffect(() => {
    if (drawerVehiculosToggle) {
      _getVehicles();
    }
  }, [drawerVehiculosToggle]);

  const _getVehicles = async () => {
    const polaris = await API.getVehiculos(1);
    const canam = await API.getVehiculos(2);

    setPolarisModelos(polaris.data);
    setCanamModelos(canam.data);
    setLoader(false);
  };

  const _selectVehiculo = (item) => {
    setVehiculoSeleccionado(item);
    localStorage.setItem("selectedVehicle", JSON.stringify(item));
    Alert.success(`${item.nombre} selected`);
    setDrawerVehiculosToggle(false);
    navigation._navigate(`/vehicle/${item.slug}/${item.id}`);
  };

  const _saveVehiculoCliente = async (id) => {
    const request = {
      id_vehiculo: id,
      id_cliente: cliente._data().id,
    };
    const { data } = await API.SaveVehiculoCliente(request);

    if (data.status !== false) {
      setDrawerVehiculosToSave(false);
      setDrawerVehiculosToggle(false);
      Alert.success("Account vehicles updated successfully.");
      window.location.reload();
    } else {
      setDrawerVehiculosToSave(false);
      setDrawerVehiculosToggle(false);
      Alert.success("We couldn't update the Account vehicles");
    }
  };

  const VehicleListItem = (props) => {
    return (
      <div
        className={
          vehiculoSeleccionado !== false
            ? vehiculoSeleccionado.id === props.item.id
              ? "drawer__list-vehicles__item--active"
              : "drawer__list-vehicles__item"
            : "drawer__list-vehicles__item"
        }
        onClick={() =>
          drawerVehiculosToSave
            ? _saveVehiculoCliente(props.item.id)
            : _selectVehiculo(props.item)
        }
      >
        <img src={utvIcon}></img>
        <div>
          <span className="drawer__list-vehicles__item__name">
            {props.item.nombre}
          </span>
          <div className="drawer__list-vehicles__item__info">
            <span>{props.item.marca}</span>
            <span>{props.item.plazas} places</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      backdropClassName="drawer"
      show={drawerVehiculosToggle}
      onHide={() => setDrawerVehiculosToggle(false)}
      size="sm"
    >
      <Drawer.Header onHide={() => setDrawerVehiculosToggle(false)}>
        <Drawer.Title style={{ color: color.text }}>
          Select your vehicle
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        Our website will give you products suggestions for the vehicle selected.
        <div style={{ marginTop: 10 }}>
          <span
            style={{ display: "block", marginBottom: 3, fontWeight: "bold" }}
          >
            Brand
          </span>
          <SelectPicker
            searchable={false}
            value={marca}
            cleanable={false}
            onChange={(val) => setMarca(val)}
            data={[
              {
                label: "Can-am",
                value: "canam",
              },
              {
                label: "Polaris",
                value: "polaris",
              },
            ]}
            style={{ width: "100%" }}
            placeholder="Select brand"
          />
        </div>
        {loader ? (
          <Loading text="loading models"></Loading>
        ) : (
          <div className="drawer__list-vehicles">
            {marca === "canam" && (
              <div>
                <h2 style={{ marginTop: 10, marginBottom: 5 }}>Canam</h2>
                {canamModelos.map((item, index) => {
                  return (
                    <VehicleListItem key={index} item={item}></VehicleListItem>
                  );
                })}
              </div>
            )}
            {marca === "polaris" && (
              <div>
                <h2 style={{ marginTop: 10, marginBottom: 5 }}>Polaris</h2>
                {polarisModelos.map((item, index) => {
                  return (
                    <VehicleListItem key={index} item={item}></VehicleListItem>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerVehiculos;

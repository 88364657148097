import React, { useState, useContext } from "react";
import { Col, Container, Row, Table, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Message } from "rsuite";
import MainLayout from "../../../components/web/Layout/MainLayout";
import StoreContext from "../../../state/store";
import SectionTitle from "../../../components/web/sectionTitle";
import { AiOutlineDelete } from "react-icons/ai";
import color from "../../../constants/color";
import useCliente from "../../../hooks/useCliente";
import APICLASS from "../../../api/web";
const API = new APICLASS();

const SUPage = (props) => {
  const cliente = useCliente();
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const _navigate = (path) => {
    history.push(`${path}`);
  };

  const _register = async () => {
    setLoader(true);
    setError("");
    const request = {
      nombre: nombre,
      apellidos: apellidos,
      email: email,
      password: password,
    };

    const res = await API.RegistroCliente(request);

    if (res.data.error) {
      setError(res.data.errorText);
    } else {
      cliente._setCliente(res.data.data.cliente);
      history.push("/account");
    }

    setLoader(false);
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col>
              <div className="login">
                <SectionTitle
                  title="Sign Up"
                  caption="Please sign up by entering your email and password"
                ></SectionTitle>

                <Row className="justify-content-md-center">
                  <Col xs={6}>
                    <div className="login__login">
                      <h4>Create new account</h4>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            value={nombre}
                            onChange={(val) => setNombre(val.target.value)}
                            type="text"
                            placeholder="Enter your first name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            value={apellidos}
                            onChange={(val) => setApellidos(val.target.value)}
                            type="text"
                            placeholder="Enter your last name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            value={email}
                            onChange={(val) => setEmail(val.target.value)}
                            type="email"
                            placeholder="Enter email"
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            value={password}
                            onChange={(val) => setPassword(val.target.value)}
                            type="password"
                            placeholder="Password"
                          />
                        </Form.Group>

                        {error !== "" && (
                          <Message
                            type="error"
                            title="Error"
                            description={<p>{error}</p>}
                          />
                        )}

                        <Button
                          style={{ marginTop: 20 }}
                          variant="primary"
                          block
                          disabled={loader ? true : false}
                          onClick={() => _register()}
                        >
                          {loader ? "Loading…" : "SUBMIT"}
                        </Button>
                      </Form>
                    </div>

                    <div className="login__register__action">
                      <span>Do you have an account?</span>
                      <div className="login__register__action">
                        <Button
                          onClick={() => _navigate("/login")}
                          variant="outline-light"
                          block
                        >
                          LOGIN
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default SUPage;

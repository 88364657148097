import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../components/web/ScrollToTop";
import AccountPage from "../views/web/account";
import CanamPage from "../views/web/canam";
import CartPage from "../views/web/cart";
import CategoryPage from "../views/web/category";
import CheckOutPage from "../views/web/checkOut";
import HomePage from "../views/web/home";
import LoginPage from "../views/web/login";
import PolarisPage from "../views/web/polaris";
import ProductPage from "../views/web/product";
import SUPage from "../views/web/sign-up";
import StoreState, { StoreProvider } from "../state/store";
import APICLASS from "../api/web";
import CategoryListPage from "../views/web/category/list";
import VehiclePage from "../views/web/vehicle";
import CheckOutConfirmationPage from "../views/web/checkOut/confirmation";
import PostsPage from "../views/web/posts";
import CheckOutErrorPage from "../views/web/checkOut/error";
import Contact from "../views/web/contact";
import TopBarWeb from "../components/web/topBar";
const API = new APICLASS();

const PublicNavigation = (props) => {
  const {
    cliente,
    setCliente,
    vehiculos,
    setVehiculos,
    setVehiculoSeleccionado,
  } = useContext(StoreState);

  useEffect(() => {
    if (localStorage.getItem("cuid") !== null) {
      _getCliente();
    }
  }, []);

  const _getCliente = async () => {
    const { data } = await API.getCliente(localStorage.getItem("cuid"));
    if (data.cliente !== false) {
      setCliente(data.cliente);
      setVehiculos(data.vehiculos);
    }
  };

  const navigation = useHistory;
  return (
    <>
      <Router>
        <TopBarWeb></TopBarWeb>
        <ScrollToTop />
        <Switch>
          <Route exact path="/home">
            {(navigation) => {
              return <HomePage props={navigation}></HomePage>;
            }}
          </Route>

          <Route exact path="/categories/:slug/:id">
            {(navigation) => {
              return <CategoryPage props={navigation}></CategoryPage>;
            }}
          </Route>

          <Route exact path="/product/:slug/:id">
            {(navigation) => {
              return <ProductPage props={navigation}></ProductPage>;
            }}
          </Route>

          <Route exact path="/polaris">
            {(navigation) => {
              return <PolarisPage props={navigation}></PolarisPage>;
            }}
          </Route>

          <Route exact path="/posts">
            {(navigation) => {
              return <PostsPage props={navigation}></PostsPage>;
            }}
          </Route>

          <Route exact path="/vehicle/:slug/:id">
            {(navigation) => {
              return <VehiclePage props={navigation}></VehiclePage>;
            }}
          </Route>

          <Route exact path="/canam">
            {(navigation) => {
              return <CanamPage props={navigation}></CanamPage>;
            }}
          </Route>

          <Route exact path="/contact">
            {(navigation) => {
              return <Contact props={navigation}></Contact>;
            }}
          </Route>

          <Route exact path="/cart">
            {(navigation) => {
              return <CartPage props={navigation}></CartPage>;
            }}
          </Route>

          <Route exact path="/check-out">
            {(navigation) => {
              return <CheckOutPage props={navigation}></CheckOutPage>;
            }}
          </Route>

          <Route exact path="/check-out/confirm/:uid">
            {(navigation) => {
              return (
                <CheckOutConfirmationPage
                  props={navigation}
                ></CheckOutConfirmationPage>
              );
            }}
          </Route>

          <Route exact path="/check-out/error">
            {(navigation) => {
              return <CheckOutErrorPage props={navigation}></CheckOutErrorPage>;
            }}
          </Route>

          <Route exact path="/account/:section">
            {(navigation) => {
              return <AccountPage props={navigation}></AccountPage>;
            }}
          </Route>

          <Route exact path="/login">
            {(navigation) => {
              return <LoginPage props={navigation}></LoginPage>;
            }}
          </Route>

          <Route exact path="/sign-up">
            {(navigation) => {
              return <SUPage props={navigation}></SUPage>;
            }}
          </Route>

          <Route exact path="/categories">
            {(navigation) => {
              return <CategoryListPage props={navigation}></CategoryListPage>;
            }}
          </Route>

          <Route path="/">
            {(navigation) => {
              return <HomePage props={navigation}></HomePage>;
            }}
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default PublicNavigation;

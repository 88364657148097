import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import APICLASS from "../../../api/web";
import MainLayout from "../../../components/web/Layout/MainLayout";
import color from "../../../constants/color";
import pathImage from "../../../constants/pathImages";
import BG from "../../../assets/img/bg.jpg";
import { Input } from "rsuite";

const API = new APICLASS();

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  return (
    <div>
      <MainLayout>
        <div
          className="hero-section-page"
          style={{
            backgroundImage: `url(${BG})`,
          }}
        >
          <div className="hero-section-page-tint">
            <div
              className="hero-section-page__caption animate__animated animate__fadeIn"
              style={{ textAlign: "center" }}
            >
              <h4 style={{ color: color.accent }}>INFO</h4>
              <h1>CONTACT</h1>

              <nav aria-label="breadcrumb" style={{ textAlign: "center" }}>
                <ol className="breadcrumb" style={{ textAlign: "center" }}>
                  <li className="breadcrumb-item">
                    <a
                      style={{ color: "white" }}
                      className="bread-item"
                      href="/"
                    >
                      Home
                    </a>
                  </li>

                  <li
                    style={{ color: color.accent }}
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    CONTACT
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <Container>
          <div className="contactForm">
            <Row>
              <Col xs={12} md={6}>
                <div className="form__item">
                  <span className="form__item__label">
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <Input
                    value={name}
                    onChange={(val) => setName(val)}
                    placeholder="Name"
                    style={{ marginBottom: 15 }}
                  ></Input>
                </div>

                <div className="form__item">
                  <span className="form__item__label">
                    Email
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <Input
                    value={email}
                    onChange={(val) => setEmail(val)}
                    placeholder="Email"
                    style={{ marginBottom: 15 }}
                  ></Input>
                </div>

                <div className="form__item">
                  <span className="form__item__label">
                    Subject
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <Input
                    value={subject}
                    onChange={(val) => setSubject(val)}
                    placeholder="Subject"
                    style={{ marginBottom: 15 }}
                  ></Input>
                </div>

                <div className="form__item">
                  <span className="form__item__label">
                    Message
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <Input
                    value={subject}
                    onChange={(val) => setSubject(val)}
                    placeholder="Subject"
                    componentClass="textarea"
                    rows={3}
                    placeholder="Message"
                    style={{ marginBottom: 15 }}
                  ></Input>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button style={{ marginTop: 20, width: 200 }}>SEND</Button>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <h1>USA</h1>
                <p>
                  Phone +1 281 650 1840 <br />
                  Email: dmxperformanceusa@gmail.com
                </p>
                <h3
                  style={{
                    marginTop: 10,
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  Warehouse to Shipping USA{" "}
                </h3>
                2111 Humble Westfield Rd, Houston TX 77073 +1 281 650 1840{" "}
                <br />
                Email: dmxperformanceusa@gmail.com
              </Col>
            </Row>
          </div>
        </Container>
      </MainLayout>
    </div>
  );
};

export default ContactPage;

import React, { useCallback, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import Loader from "../../../components/web/Loader";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import { useQuery } from "react-query";
import Loading from "../../../components/web/Loading";

const API = new APICLASS();

const ProductosNuevos = () => {
  const _getNuevos = async () => {
    const { data } = await API.getProductosNuevos();
    if (Array.isArray(data)) {
      return data;
    }
    return [];
  };

  const {
    data: productos,
    isLoading,
    isError,
  } = useQuery("productosNuevos", _getNuevos);

  const ProductosCallBack = useCallback(() => {
    return (
      <div>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <div className="section-wrap" style={{ marginTop: 0 }}>
            <SectionTitle
              title="New Products"
              caption="Last added products"
            ></SectionTitle>

            {productos.length > 0 ? (
              <div>
                <div className="grid-flex">
                  {productos.map((item, index) => {
                    return (
                      <ProductRow
                        key={index}
                        name={item.producto.nombre}
                        priceRegular={`${item.producto.precio}`}
                        priceSale={`${item.producto.precio_oferta}`}
                        image={item.portada}
                        id={item.producto.id}
                        slug={item.producto.slug}
                      ></ProductRow>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Loader></Loader>
            )}
          </div>
        )}
      </div>
    );
  }, [productos]);

  return <ProductosCallBack></ProductosCallBack>;
};

export default ProductosNuevos;

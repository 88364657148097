import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import APICLASS from "../../../api/web";
import CategoryRow from "../../../components/web/CategoryRow";
import MainLayout from "../../../components/web/Layout/MainLayout";
import Loading from "../../../components/web/Loading";
import SectionTitle from "../../../components/web/sectionTitle";
const API = new APICLASS();

const CategoryListPage = () => {
  const _getCategories = async () => {
    const { data } = await API.getCategories();
    if (Array.isArray(data)) {
      return data;
    }

    return [];
  };

  const {
    data: categories,
    isLoading,
    isError,
  } = useQuery("categoriesPage", _getCategories);

  const CategoryList = () => {
    return (
      <div>
        <SectionTitle title="Categories" caption="All"></SectionTitle>

        {isLoading ? (
          <Loading text="Loading categories..."></Loading>
        ) : (
          <div>
            {categories.length > 0 ? (
              <div className="grid-flex">
                {categories.map((item, index) => {
                  return (
                    <CategoryRow
                      key={index}
                      name={item.nombre}
                      image={item.imagen_portada}
                      slug={item.slug}
                      id={item.id}
                    ></CategoryRow>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col xs={12}>
              <CategoryList></CategoryList>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default CategoryListPage;

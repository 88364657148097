import React from "react";
import color from "../../../constants/color";
import Polaris2P from "../../../assets/img/polaris-2-white.png";
import Polaris4P from "../../../assets/img/polaris-4-white.png";
import Canam2P from "../../../assets/img/canam-2-white.png";
import Canam4P from "../../../assets/img/canam-4-white.png";
import utvIcon from "../../../assets/img/utv-icon.png";
import useNagivation from "../../../hooks/useNavigation";

const Vehicle = (props) => {
  const navigation = useNagivation();
  return (
    <div
      className={props.full ? "vehicleFull vehicle" : "vehicle"}
      style={{
        border: "2px solid",
        borderColor: props.selected ? color.accent : "transparent",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
      onClick={() => navigation._navigate(`/vehicle/${props.slug}/${props.id}`)}
    >
      <div className="vehicle__img">
        {props.id_categoria === "1" ? (
          <img src={props.plazas == "2" ? Polaris2P : Polaris4P}></img>
        ) : (
          <img src={props.plazas == "4" ? Canam4P : Canam2P}></img>
        )}
      </div>

      <div className="vehicle__info">
        <div className="vehicle__info__brand">
          <span style={{ fontWeight: "bold" }}>{props.brand}</span>
          <h6>{props.plazas} seats</h6>
        </div>

        <div className="vehicle__info__name">
          <span>{props.name}</span>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;

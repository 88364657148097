import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Message } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import MainLayout from "../../../components/web/Layout/MainLayout";
import Loading from "../../../components/web/Loading";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import color from "../../../constants/color";
import pathImage from "../../../constants/pathImages";
import useNavigation from "../../../hooks/useNavigation";
import StoreState from "../../../state/store";
import PORTADA from "../../../assets/img/hero2.jpg";
import pathFiles from "../../../constants/pathImages";
import PostRow from "../../../components/web/postRow";

const API = new APICLASS();

const PostsPage = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();

  const { vehiculoSeleccionado, setVehiculoSeleccionado, categorias } =
    useContext(StoreState);

  const [posts, setPosts] = useStateIfMounted([]);
  const [postsFiltrados, setPostsFiltrados] = useStateIfMounted([]);
  const [canamModelos, setCanamModelos] = useStateIfMounted([]);
  const [polarisModelos, setPolarisModelos] = useStateIfMounted([]);
  const [loader, setLoader] = useStateIfMounted(true);

  useEffect(() => {
    _get();
    _getPolarisModelos();
    _getCanamModelos();
  }, []);

  const _get = async () => {
    const { data } = await API.getPostsTodos();
    if (data !== false) {
      setPosts(data);
      setPostsFiltrados(data);
    }
    setLoader(false);
  };

  const _getCanamModelos = async () => {
    const { data } = await API.getVehiculos(2);
    if (data !== false) {
      setCanamModelos(data);
    }
  };

  const _getPolarisModelos = async () => {
    const { data } = await API.getVehiculos(1);
    if (data !== false) {
      setPolarisModelos(data);
    }
  };

  const Posts = () => {
    return (
      <div>
        <SectionTitle title={"Posts"} caption={"All posts"}></SectionTitle>

        {postsFiltrados.length > 0 ? (
          <div className="grid-flex animate__animated animate__fadeIn">
            {postsFiltrados.map((item, index) => {
              return (
                <PostRow
                  key={index}
                  nombre={item.cliente_nombre}
                  fecha={item.created_at}
                  item={item}
                  imagen={`${pathFiles}/${item.imagen_portada}`}
                  descripcion={item.descripcion}
                  vehiculo={item.vehiculo_nombre}
                  marca={item.marca_nombre}
                ></PostRow>
              );
            })}
          </div>
        ) : (
          <Message
            type="warning"
            title="We couldn't find posts"
            description={
              <p>
                {`We couldn't find posts at the momment`}
                <br />
                Please try later.
              </p>
            }
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <MainLayout>
        <div
          className="hero-section-page"
          style={{
            backgroundImage: `url(${PORTADA})`,
          }}
        >
          <div className="hero-section-page-tint">
            <div
              className="hero-section-page__caption animate__animated animate__fadeIn"
              style={{ textAlign: "center" }}
            >
              <h4 style={{ color: color.accent }}>
                Gallery uploded by our users
              </h4>
              <h1>{`Customer builds`}</h1>

              <nav aria-label="breadcrumb" style={{ textAlign: "center" }}>
                <ol className="breadcrumb" style={{ textAlign: "center" }}>
                  <li className="breadcrumb-item">
                    <a
                      style={{ color: "white" }}
                      className="bread-item"
                      href="/"
                    >
                      Home
                    </a>
                  </li>

                  <li
                    style={{ color: color.accent }}
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    POSTS
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <Container>
          <Row>
            <Col xs={12}>
              {loader ? (
                <Loading text="Loading posts data..."></Loading>
              ) : (
                <Posts></Posts>
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default PostsPage;

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../components/web/Layout/MainLayout";
import SectionTitle from "../../../components/web/sectionTitle";
import { AiOutlineDelete } from "react-icons/ai";
import color from "../../../constants/color";
import StoreState from "../../../state/store";
import APICLASS from "../../../api/web";
import { Message, Alert } from "rsuite";
import { FaBullseye } from "react-icons/fa";
import Loading from "../../../components/web/Loading";

const API = new APICLASS();

const LoginPage = (props) => {
  const { cliente, setCliente, vehiculos, setVehiculos } =
    useContext(StoreState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (cliente !== false) {
      _navigate("/account");
    }
  }, [cliente]);

  const _login = async () => {
    if (email == "" || password == "") {
      setError("Please fill in the required fields");
      return;
    }
    setLoader(true);
    setError(false);
    const sessionData = await API.getUserDataSession();

    const { data } = await API.loginCliente(email, password, sessionData.data);
    if (!data.error && data.cliente.cliente !== false) {
      setCliente(data.cliente.cliente);
      setVehiculos(data.cliente.vehiculos);
      localStorage.setItem("cuid", data.cliente.cliente.uid);
    } else {
      setError(data.error_message);
    }

    setLoader(false);
  };

  const _navigate = (path) => {
    history.push(`${path}`);
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col>
              <div className="login">
                <SectionTitle
                  title="Login"
                  caption="Please login by entering your email and password"
                ></SectionTitle>

                <Row className="justify-content-md-center">
                  <Col xs={6}>
                    <div className="login__login">
                      <h4>Your account</h4>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(val) => setEmail(val.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(val) => setPassword(val.target.value)}
                          />
                        </Form.Group>

                        {error !== false && (
                          <div className="login__error">
                            <Message
                              type="error"
                              showIcon
                              description={<p>{error}</p>}
                            />
                          </div>
                        )}

                        {loader ? (
                          <Loading text="Loading data..."></Loading>
                        ) : (
                          <Button
                            style={{ marginTop: 20 }}
                            variant="primary"
                            block
                            onClick={() => _login()}
                          >
                            LOGIN
                          </Button>
                        )}
                      </Form>
                    </div>

                    <div className="login__register__action">
                      <span>or</span>
                      <div className="login__register__action">
                        <Button
                          onClick={() => _navigate("sign-up")}
                          variant="outline-light"
                          block
                        >
                          CREATE NEW ACCOUNT
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default LoginPage;

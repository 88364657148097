import React from "react";
import color from "../../../constants/color";
import Polaris2P from "../../../assets/img/polaris-2-white.png";
import Polaris4P from "../../../assets/img/polaris-4-white.png";
import Canam2P from "../../../assets/img/canam-2-white.png";
import Canam4P from "../../../assets/img/canam-4-white.png";
import utvIcon from "../../../assets/img/utv-icon.png";
import useNagivation from "../../../hooks/useNavigation";
import { Button } from "react-bootstrap";
import APICLASS from "../../../api/web";
import useCliente from "../../../hooks/useCliente";
import { useStateIfMounted } from "use-state-if-mounted";
import { Message, Alert } from "rsuite";

const API = new APICLASS();

const VehicleCliente = (props) => {
  const cliente = useCliente();
  const navigation = useNagivation();

  const [loader, setLoader] = useStateIfMounted(false);

  const _removeFromVC = async () => {
    setLoader(true);
    const request = {
      id_vehiculo: props.id,
      id_cliente: cliente._data().id,
    };
    const { data } = await API.RemoveVehiculoCliente(request);

    if (data.status) {
      Alert.success("Vehicle removed successfully.");
      window.location.reload();
    } else {
      Alert.error("Error.");
    }
    setLoader(false);
  };
  return (
    <div
      className="vehicle"
      style={{
        border: "2px solid",
        borderColor: props.selected ? color.accent : "transparent",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
    >
      <div className="vehicle__remove" onClick={() => _removeFromVC()}>
        x
      </div>
      <div className="vehicle__img">
        {props.id_categoria === "1" ? (
          <img src={props.plazas === "2" ? utvIcon : utvIcon}></img>
        ) : (
          <img src={props.plazas === "4" ? utvIcon : utvIcon}></img>
        )}
      </div>

      <div
        className="vehicle__info"
        onClick={() =>
          navigation._navigate(`/vehicle/${props.slug}/${props.id}`)
        }
      >
        <div className="vehicle__info__brand">
          <span style={{ fontWeight: "bold" }}>{props.brand}</span>
          <h6>{props.plazas} seats</h6>
        </div>

        <div className="vehicle__info__name">
          <span>{props.name}</span>
        </div>
      </div>
    </div>
  );
};

export default VehicleCliente;

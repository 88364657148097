import React from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../components/web/Layout/MainLayout";
import SectionTitle from "../../../components/web/sectionTitle";
import filePath from "../../../constants/pathImages";
import { AiOutlineDelete } from "react-icons/ai";
import useCarrito from "../../../hooks/useCarrito";
import NumberFormat from "react-number-format";
import { FiShoppingCart } from "react-icons/fi";

import color from "../../../constants/color";

const CartPage = (props) => {
  const history = useHistory();
  const carrito = useCarrito();

  const _navigate = (path) => {
    history.push(`${path}`);
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col>
              <div className="cart">
                <SectionTitle
                  title="My Cart"
                  caption="Cart totals"
                ></SectionTitle>

                {carrito._getItemsCount() > 0 ? (
                  <div>
                    <div className="cart__table">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {carrito._getItems().map((item, index) => {
                            return (
                              <tr>
                                <td className="cart__table__item__hasImage">
                                  <img
                                    src={`${filePath}/${item.portada}`}
                                  ></img>
                                  <span>{item.nombre}</span>
                                </td>
                                <td style={{ paddingTop: 28 }}>
                                  <NumberFormat
                                    value={item.precioCobrar}
                                    displayType={"text"}
                                    prefix={"$"}
                                    thousandSeparator={","}
                                    fixedDecimalScale={true}
                                    decimalSeparator="."
                                    renderText={(value, props) => (
                                      <span
                                        style={{
                                          color: !item.tieneOferta
                                            ? color.text
                                            : color.secondary,
                                        }}
                                        {...props}
                                      >
                                        {value}
                                      </span>
                                    )}
                                  />
                                </td>
                                <td style={{ paddingTop: 28 }}>
                                  <span>{item.cantidad}</span>
                                </td>
                                <td style={{ paddingTop: 28 }}>
                                  <NumberFormat
                                    value={(
                                      item.precioCobrar * item.cantidad
                                    ).toFixed(2)}
                                    displayType={"text"}
                                    prefix={"$"}
                                    thousandSeparator={","}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    renderText={(value, props) => (
                                      <span {...props}>{value}</span>
                                    )}
                                  />
                                </td>

                                <td style={{ paddingTop: 21 }}>
                                  <Button
                                    onClick={() =>
                                      carrito._removeFromCart(index)
                                    }
                                    style={{
                                      color: "red",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    variant="link"
                                  >
                                    <AiOutlineDelete
                                      style={{ marginRight: 5 }}
                                    ></AiOutlineDelete>
                                    Remove Item
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>

                      <div className="cart__total">
                        <div>
                          <span
                            style={{
                              display: "block",
                              color: color.textSecondary,
                            }}
                          >
                            Sub Total
                          </span>
                          <NumberFormat
                            value={carrito._getTotal()}
                            displayType={"text"}
                            prefix={"$"}
                            thousandSeparator={","}
                            decimalSeparator="."
                            renderText={(value, props) => (
                              <span
                                style={{ fontWeight: "bold", fontSize: 36 }}
                                {...props}
                              >
                                {value}
                              </span>
                            )}
                          />
                          <div style={{ marginTop: 5 }}>
                            <Button onClick={() => _navigate("check-out")}>
                              PROCEED TO CHECKOUT
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="empty__cart">
                    <FiShoppingCart
                      color={color.textSecondary}
                      size={90}
                    ></FiShoppingCart>
                    <span>Your cart is empty</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default CartPage;

import React from "react";

const Empty = (props) => {
  return (
    <div>
      <span>{props.text}</span>
    </div>
  );
};

export default Empty;

import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Message, Alert } from "rsuite";
import APICLASS from "../../../api/web";
import SectionTitle from "../../../components/web/sectionTitle";
import useCliente from "../../../hooks/useCliente";
const API = new APICLASS();

const Account = (props) => {
  const cliente = useCliente();
  const history = useHistory();

  const [nombre, setNombre] = useState(cliente._data().nombre);
  const [email, setEmail] = useState(cliente._data().email);
  const [apellidos, setApellidos] = useState(cliente._data().apellidos);
  const [errorUpdate, setErrorUpdate] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingLO, setLoadingLO] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");
  const [actualEmail, setActualEmail] = useState(cliente._data().email);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const _update = async () => {
    setErrorUpdate("");
    const request = {
      nombre: nombre,
      apellidos: apellidos,
      email: email,
    };

    const res = await API.RegistroCliente(request);

    if (res.data.data.error) {
      setErrorUpdate(res.data.data.errorText);
    } else {
      cliente._setCliente(res.data.data.cliente);
      history.push("/account");
    }
  };

  const _updateEmail = async () => {
    if (email === actualEmail) {
      setErrorEmail(
        "The email you are trying to update is the same one you currently have"
      );
    } else {
      setLoadingEmail(true);
      setErrorEmail("");
      const request = {
        email: email,
        id_cliente: cliente._data().id,
      };

      const { data } = await API.UpdateClienteEmail(request);

      if (data.error) {
        setErrorEmail(data.errorText);
      } else {
        setActualEmail(email);
        Alert.success("Account updated successfully.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setLoadingEmail(false);
    }
  };

  const _updateNombre = async () => {
    setLoadingUpdate(true);
    setErrorUpdate("");
    const request = {
      nombre: nombre,
      apellidos: apellidos,
      id_cliente: cliente._data().id,
    };

    const { data } = await API.UpdateClienteNombre(request);
    if (data.error) {
      setErrorUpdate(data.errorText);
    } else {
      Alert.success("Account updated successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setLoadingUpdate(false);
  };

  const _updatePassword = async () => {
    if (password === "" || rePassword === "") {
      setErrorPassword(
        "Please enter the password and the password confirmation."
      );
    } else {
      if (password !== rePassword) {
        setErrorPassword("Passwords do not match.");
      } else {
        setLoadingPassword(true);
        setErrorPassword("");
        const request = {
          password: password,
          id_cliente: cliente._data().id,
        };

        const { data } = await API.UpdateClientePassword(request);
        if (data.error) {
          setErrorPassword(data.errorText);
        } else {
          Alert.success("Account updated successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        setLoadingPassword(false);
      }
    }
  };

  const _logOut = async () => {
    setLoadingLO(true);

    cliente._logOut();

    setLoadingLO(false);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="animate__animated animate__fadeIn">
              <SectionTitle
                title={cliente._data().nombre_completo}
                caption={cliente._data().email}
              ></SectionTitle>
              {/* detalles */}
              <div className="form-box form-box--transparent">
                <h4>Update Account Details</h4>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      value={nombre}
                      onChange={(val) => setNombre(val.target.value)}
                      type="text"
                      placeholder="Enter your first name"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      value={apellidos}
                      onChange={(val) => setApellidos(val.target.value)}
                      type="text"
                      placeholder="Enter your last name"
                    />
                  </Form.Group>

                  {errorUpdate !== "" && (
                    <Message
                      type="error"
                      title="Error"
                      description={<p>{errorUpdate}</p>}
                    />
                  )}

                  <Button
                    style={{ marginTop: 20 }}
                    variant="primary"
                    disabled={loadingUpdate}
                    onClick={() => _updateNombre()}
                  >
                    {loadingUpdate ? "Loading…" : "UPDATE"}
                  </Button>
                </Form>
              </div>
              {/* /detalles */}

              <div style={{ marginTop: 40 }} className="divider-light"></div>

              {/* email */}
              <div className="form-box form-box--transparent">
                <h4>Update Email</h4>
                <Form>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      value={email}
                      onChange={(val) => setEmail(val.target.value)}
                      type="email"
                      placeholder="Enter email"
                    />
                  </Form.Group>

                  {errorEmail !== "" && (
                    <Message
                      type="error"
                      title="Error"
                      description={<p>{errorEmail}</p>}
                    />
                  )}

                  <Button
                    style={{ marginTop: 20 }}
                    variant="primary"
                    disabled={loadingEmail}
                    onClick={() => _updateEmail()}
                  >
                    {loadingEmail ? "Loading…" : "UPDATE EMAIL"}
                  </Button>
                </Form>
              </div>

              {/* /email */}

              <div style={{ marginTop: 40 }} className="divider-light"></div>

              {/* password */}
              <div className="form-box form-box--transparent">
                <h4>Update Password</h4>
                <Form>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      value={password}
                      onChange={(val) => setPassword(val.target.value)}
                      type="password"
                      placeholder="Enter new password"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      value={rePassword}
                      onChange={(val) => setRePassword(val.target.value)}
                      type="password"
                      placeholder="Confirm new password"
                    />
                  </Form.Group>

                  {errorPassword !== "" && (
                    <Message
                      type="error"
                      title="Error"
                      description={<p>{errorPassword}</p>}
                    />
                  )}

                  <Button
                    style={{ marginTop: 20 }}
                    variant="primary"
                    disabled={loadingPassword}
                    onClick={() => _updatePassword()}
                  >
                    {loadingPassword ? "Loading…" : "UPDATE PASSWORD"}
                  </Button>
                </Form>
              </div>
              {/* /password */}

              <div style={{ marginTop: 40 }} className="divider-light"></div>

              {/* logout */}
              <div className="form-box form-box--transparent">
                <h4>Log out</h4>

                <Button
                  style={{ marginTop: 20 }}
                  variant="danger"
                  disabled={loadingLO}
                  onClick={() => _logOut()}
                >
                  {loadingLO ? "Loading…" : "LOG OUT"}
                </Button>
              </div>
              {/* /logout */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Account;

import React, { useContext } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import StoreState from "../../../state/store";
import BG from "../../../assets/img/hero1.jpg";
import { Button } from "react-bootstrap";
import useNagivation from "../../../hooks/useNavigation";
import color from "../../../constants/color";
import HeroSelectAction from "../../../components/web/HeroSelectAction";
import useCliente from "../../../hooks/useCliente";

const API = new APICLASS();

const HeroNoVehiculo = () => {
  const cliente = useCliente();

  const navigation = useNagivation();
  const [productos, setProductos] = useStateIfMounted([]);
  const [loader, setLoader] = useStateIfMounted(false);

  const { vehiculoSeleccionado, setVehiculoSeleccionado } =
    useContext(StoreState);

  return (
    <div
      className="hero-section"
      style={{
        backgroundImage: `url(${BG})`,
      }}
    >
      <div
        className="hero-section__caption animate__animated animate__fadeIn"
        style={{ textAlign: "center" }}
      >
        <h4 style={{ color: "white", fontWeight: "300" }}>
          TOP COMPANY IN ACCESORIES & PARTS FOR UTV
        </h4>
        <h1>DMX PERFORMANCE!</h1>

        {cliente._data() == false ? (
          <Button onClick={() => navigation._navigate("/sign-up")}>
            SIGN UP
          </Button>
        ) : (
          <Button onClick={() => navigation._navigate("/account/my-account")}>
            MY ACCOUNT
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeroNoVehiculo;

const color = {
  text: "#ffffff",
  textSecondary: "#979797",
  textDisabled: "#606060",
  accent: "#28F0D9",
  secondary: "#fe531f",
  main: "#1a1a1a",
  mainLight: "#343434",
};

export default color;

import axios from "axios";
import api from "../constants/api";

export default class API {
  async getVehiculos(id_marca) {
    return axios.get(api, {
      params: {
        ot: "getVehiculos",
        app: "web",
        id_marca,
      },
    });
  }

  async getPostsTodos() {
    return axios.get(api, {
      params: {
        ot: "getPostsTodos",
        app: "web",
      },
    });
  }

  async getUserDataSession() {
    return axios.get("https://geolocation-db.com/json/");
  }

  async getPostsVehiculo(id_vehiculo) {
    return axios.get(api, {
      params: {
        ot: "getPostsVehiculo",
        app: "web",
        id_vehiculo,
      },
    });
  }

  async getProductosSearch(term) {
    return axios.get(api, {
      params: {
        ot: "getProductosSearch",
        app: "web",
        term,
      },
    });
  }

  async getComprasCliente(id) {
    return axios.get(api, {
      params: {
        ot: "getComprasCliente",
        app: "web",
        id,
      },
    });
  }

  async getCategories() {
    return axios.get(api, {
      params: {
        ot: "GetCategories",
        app: "web",
      },
    });
  }

  async getRecomendacionesProductosCategoria(id_marca) {
    return axios.get(api, {
      params: {
        ot: "getProductosRecomendacionesCategoria",
        app: "web",
        id_marca,
      },
    });
  }

  async getColores() {
    return axios.get(api, {
      params: {
        ot: "getColores",
        app: "web",
      },
    });
  }

  async getCotizacionEnvio(pedido) {
    return axios.post(api, {
      params: {
        ot: "getEnvioCotizacion",
        app: "web",
        pedido,
      },
    });
  }

  async saveVenta(data) {
    return axios.post(api, {
      params: {
        ot: "saveVenta",
        app: "web",
        data,
      },
    });
  }

  async saveMensaje(data) {
    return axios.post(api, {
      params: {
        ot: "SaveMensaje",
        app: "admin",
        data,
      },
    });
  }

  async comprarLabelEnvio(object_id) {
    return axios.get(api, {
      params: {
        ot: "comprarLabelEnvio",
        app: "web",
        object_id,
      },
    });
  }

  async getDetalleProducto(id) {
    return axios.get(api, {
      params: {
        ot: "getProductoDetalle",
        app: "web",
        id: id,
      },
    });
  }

  async getProductosNuevos() {
    return axios.get(api, {
      params: {
        ot: "getProductosNuevos",
        app: "web",
      },
    });
  }

  async getX3Models() {
    return axios.get(api, {
      params: {
        ot: "getX3Models",
        app: "web",
      },
    });
  }

  async getProductosFeatured() {
    return axios.get(api, {
      params: {
        ot: "getProductosFeatured",
        app: "web",
      },
    });
  }

  async getSugerenciasDeProductosParaVehiculoSeleccionado(id_vehiculo) {
    return axios.get(api, {
      params: {
        ot: "GetProductosRecomendadosVehiculo",
        app: "web",
        id_vehiculo,
      },
    });
  }

  async getProductosMasVendidosDeUnVehiculo(id_vehiculo) {
    return axios.get(api, {
      params: {
        ot: "getProductosMasVendidosDeUnVehiculo",
        app: "web",
        id_vehiculo,
      },
    });
  }

  async getInfoCompletaVehiculo(id_vehiculo) {
    return axios.get(api, {
      params: {
        ot: "getInfoCompletaVehiculo",
        app: "web",
        id_vehiculo,
      },
    });
  }

  async getInfoCategoria(id_categoria) {
    return axios.get(api, {
      params: {
        ot: "getInfoCategoria",
        app: "web",
        id_categoria,
      },
    });
  }

  async getPostsCliente(id_cliente) {
    return axios.get(api, {
      params: {
        ot: "getPostsCliente",
        app: "web",
        id_cliente,
      },
    });
  }

  async getCliente(uid) {
    return axios.get(api, {
      params: {
        ot: "getCliente",
        app: "web",
        uid,
      },
    });
  }

  async loginCliente(email, password, sessionData) {
    return axios.get(api, {
      params: {
        ot: "LoginCliente",
        app: "web",
        email,
        password,
        sessionData,
      },
    });
  }

  async RegistroCliente(data) {
    return axios.post(api, {
      params: {
        ot: "RegistroCliente",
        app: "web",
        data,
      },
    });
  }

  async SaveNewCart(data) {
    return axios.post(api, {
      params: {
        ot: "SaveNwCart",
        app: "web",
        data,
      },
    });
  }

  async SaveVehiculoCliente(data) {
    return axios.post(api, {
      params: {
        ot: "saveVehiculoCliente",
        app: "web",
        data,
      },
    });
  }

  async SavePost(data) {
    return axios.post(api, {
      params: {
        ot: "savePost",
        app: "web",
        data,
      },
    });
  }

  async RemoveVehiculoCliente(data) {
    return axios.post(api, {
      params: {
        ot: "deleteVehiculoCliente",
        app: "web",
        data,
      },
    });
  }

  async UpdateClienteEmail(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateClienteEmail",
        app: "web",
        data,
      },
    });
  }

  async UpdateClienteDireccion(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateClienteDireccion",
        app: "web",
        data,
      },
    });
  }

  async DeltePostCliente(data) {
    return axios.post(api, {
      params: {
        ot: "deletePostCliente",
        app: "web",
        data,
      },
    });
  }

  async UpdateClientePassword(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateClientePassword",
        app: "web",
        data,
      },
    });
  }

  async UpdateClienteNombre(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateClienteNombre",
        app: "web",
        data,
      },
    });
  }
}

import React, { useContext, useEffect } from "react";
import { Button, Container, Col, Row, Breadcrumb } from "react-bootstrap";
import MainLayout from "../../../components/web/Layout/MainLayout";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import SideBarCategory from "./sidebar";
import StoreState from "../../../state/store";
import { Message, Tag } from "rsuite";
import UTVImage from "../../../assets/img/utv-icon-accent.png";
import APICLASS from "../../../api/web";
import useNavigation from "../../../hooks/useNavigation";
import { useStateIfMounted } from "use-state-if-mounted";
import _ from "lodash";
import color from "../../../constants/color";
import pathImage from "../../../constants/pathImages";
import Loading from "../../../components/web/Loading";
import {
  SideBar,
  SidebarDivider,
  SidebarTitle,
  SidebarList,
} from "../../../components/web/SideBar";

const API = new APICLASS();

const CategoryPage = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();

  const { vehiculoSeleccionado, setVehiculoSeleccionado, categorias } =
    useContext(StoreState);

  const [productos, setProductos] = useStateIfMounted([]);
  const [productosFiltrados, setProductosFiltrados] = useStateIfMounted([]);
  const [canamModelos, setCanamModelos] = useStateIfMounted([]);
  const [polarisModelos, setPolarisModelos] = useStateIfMounted([]);

  const [categoria, setCategoria] = useStateIfMounted([]);
  const [vehiculoFilter, setVehiculoFilter] = useStateIfMounted(false);

  const [loader, setLoader] = useStateIfMounted(true);

  const _filtrarPorModelo = (item) => {
    setVehiculoFilter(item);
    const filtrados = _.filter(productos, {
      vehiculos: { id_vehiculo: "24" },
    });

    setProductosFiltrados(filtrados);
  };

  const _removeFilter = () => {
    setProductosFiltrados(productos);
    setVehiculoFilter(false);
  };

  useEffect(() => {
    _getInfoCategoria();
    _getPolarisModelos();
    _getCanamModelos();
  }, []);

  const _getInfoCategoria = async () => {
    const { data } = await API.getInfoCategoria(params.id);
    if (data !== false) {
      setProductos(data.productos);
      setProductosFiltrados(data.productos);
      setCategoria(data.categoria);
    }

    setLoader(false);
  };

  const _getCanamModelos = async () => {
    const { data } = await API.getVehiculos(2);
    if (data !== false) {
      setCanamModelos(data);
    }
  };

  const _getPolarisModelos = async () => {
    const { data } = await API.getVehiculos(1);
    if (data !== false) {
      setPolarisModelos(data);
    }
  };

  const Products = () => {
    return (
      <div>
        <SectionTitle
          title={categoria.nombre}
          caption={"All products"}
        ></SectionTitle>

        {productosFiltrados.length > 0 ? (
          <div className="grid-flex animate__animated animate__fadeIn">
            {productosFiltrados.map((item, index) => {
              return (
                <ProductRow
                  key={index}
                  name={item.producto.nombre}
                  priceRegular={`${item.producto.precio}`}
                  priceSale={`${item.producto.precio_oferta}`}
                  image={item.portada}
                  id={item.producto.id}
                  slug={item.producto.slug}
                ></ProductRow>
              );
            })}
          </div>
        ) : (
          <Message
            type="warning"
            title="We couldn't find products"
            description={
              <p>
                {`We couldn't find products for ${categoria.nombre} at the momment`}{" "}
                <br />
                Please try using other category.
              </p>
            }
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <MainLayout>
        {loader ? (
          <Loading big text="Loading category data..."></Loading>
        ) : (
          <div>
            <div
              className="hero-section-page"
              style={{
                backgroundImage: `url(${pathImage}${categoria.imagen_portada})`,
              }}
            >
              <div className="hero-section-page-tint">
                <div
                  className="hero-section-page__caption animate__animated animate__fadeIn"
                  style={{ textAlign: "center" }}
                >
                  <h4 style={{ color: color.accent }}>CATEGORY</h4>
                  <h1>{`${categoria.nombre}`}</h1>

                  <nav aria-label="breadcrumb" style={{ textAlign: "center" }}>
                    <ol className="breadcrumb" style={{ textAlign: "center" }}>
                      <li className="breadcrumb-item">
                        <a
                          style={{ color: "white" }}
                          className="bread-item"
                          href="/"
                        >
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a style={{ color: "white" }} href="/categories">
                          Categories
                        </a>
                      </li>
                      <li
                        style={{ color: color.accent }}
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {categoria.nombre}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <Container>
              <Row>
                <Col xs={12}>
                  <Products></Products>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </MainLayout>
    </div>
  );
};

export default CategoryPage;

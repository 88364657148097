import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import CategoryRow from "../../../components/web/CategoryRow";
import Loading from "../../../components/web/Loading";
import SectionTitle from "../../../components/web/sectionTitle";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useQuery } from "react-query";

const API = new APICLASS();

const CategoriesSlide = () => {
  const _getCategories = async () => {
    const { data } = await API.getCategories();
    return data;
  };

  const {
    data: categories,
    isLoading,
    isError,
  } = useQuery("categoriesSlide", _getCategories);

  return (
    <>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="section-wrap" style={{ marginTop: 20 }}>
          <SectionTitle
            title="Categories"
            caption="Products by category"
          ></SectionTitle>
          <OwlCarousel className="owl-theme" loop margin={10} nav dots={false}>
            {categories.length > 0 ? (
              <>
                {categories.map((item, index) => {
                  return (
                    <div class="item">
                      <CategoryRow
                        full
                        key={index}
                        name={item.nombre}
                        image={item.imagen_portada}
                        slug={item.slug}
                        id={item.id}
                      ></CategoryRow>
                    </div>
                  );
                })}
              </>
            ) : null}
          </OwlCarousel>
        </div>
      )}
    </>
  );
};

export default CategoriesSlide;

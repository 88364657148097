import React from "react";
import { Loader } from "rsuite";

const Loading = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: props.big ? 800 : 100,
      }}
    >
      <Loader content={props.text} vertical />
    </div>
  );
};

export default Loading;

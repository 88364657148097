import BG from "../../../assets/img/bg.jpg";
import { Button } from "react-bootstrap";
import color from "../../../constants/color";

const CatalogoBanner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BG})`,
        height: 400,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundAttachment: "fixed",
      }}
    >
      <h1>ACCESORIES CATALOG 2022</h1>
      <Button
        style={{
          background: color.secondary,
          color: "white",
          borderColor: color.secondary,
        }}
        onClick={() =>
          window.open(
            "https://dmxperformance.com/cat2022/dmx2022.pdf",
            "_blank"
          )
        }
      >
        DOWNLOAD ACCESORIES CATALOG
      </Button>
    </div>
  );
};

export default CatalogoBanner;

import React from "react";
import LOGO from "../../../assets/img/logo.png";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoWhatsapp,
  IoMailOutline,
} from "react-icons/io5";
import { Row, Col, Container } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <div className="topFooter">
        <Container>
          <Row>
            <Col>
              {" "}
              <div className="topFooter-main">
                <div className="topFooter-main__About">
                  <h3>ABOUT DMX PERFORMANCE</h3>
                  <p>
                    DMX Performance is a synonym with innovation and leadership,
                    since its creation as the only 100% Mexican company
                    specialized in creating design, customization and
                    distribution of ATV and UTV accessories, we have been
                    concerned with offering the best quality on all our products
                    improving it day by day without neglecting the aesthetics of
                    the same ones.
                    <br />
                    <br />
                    As a registered trademark and respected by its customers DMX
                    Performance has a qualified workforce and follows all the
                    safety standards required for the optimal performance of any
                    of our products and above all to continue to satisfy our
                    customers.
                    <br />
                    <br />
                    The operation of DMX has an ideal balance between
                    protection, quality, variety and design that allow us to
                    continue growing as a company and not only the unique brand
                    distinguished and with the best quality in the America
                    country, but also worldwide exporting, the expectation of
                    our impulses to have a very high quality by world standards.
                    <br />
                    <br />
                    Our company is concerned with being in constant renewal,
                    creation and perfection on our products to maintain us as
                    reference in sale of ATV and UTV accessories in the United
                    States, Mexico and the world.
                  </p>
                </div>

                <div className="topFooter-main__social">
                  <div className="topFooter-main__social__item">
                    <IoLogoFacebook size={28} color="white"></IoLogoFacebook>
                  </div>
                  <div className="topFooter-main__social__item">
                    <IoLogoInstagram size={28} color="white"></IoLogoInstagram>
                  </div>
                  <div className="topFooter-main__social__item">
                    <IoLogoYoutube size={28} color="white"></IoLogoYoutube>
                  </div>
                  <div className="topFooter-main__social__item">
                    <IoLogoWhatsapp size={28} color="white"></IoLogoWhatsapp>
                  </div>
                  <div className="topFooter-main__social__item">
                    <IoMailOutline size={28} color="white"></IoMailOutline>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer">
        <div>Todos los derechos reservados DMX PERFORMANCE 2021</div>
        <div>
          <a href="https://lecxa.com.mx">by Lecxa</a>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Col, Row, Alert } from "react-bootstrap";
import MainLayout from "../../../components/web/Layout/MainLayout";
import SectionTitle from "../../../components/web/sectionTitle";
import StoreContext from "../../../state/store";
import VehiculosAccount from "./vehiculos.js";
import { useHistory } from "react-router-dom";
import useNavigation from "../../../hooks/useNavigation";
import OrdersAccount from "./orders";
import Account from "./account";
import AccountPost from "./post";
import Adress from "./adress";

import useCliente from "../../../hooks/useCliente";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStateIfMounted } from "use-state-if-mounted";
import color from "../../../constants/color";

const AccountPage = (props) => {
  const { section } = useParams();
  const cliente = useCliente();
  const navigation = useNavigation();
  const [sectionActive, setSectionActive] = useStateIfMounted(section);

  useEffect(() => {
    setSectionActive(section);
  }, [section]);

  const sections = [
    {
      label: "My Orders",
      slug: "my-orders",
    },
    {
      label: "My Vehicles",
      slug: "my-vehicles",
    },

    {
      label: "My Posts",
      slug: "my-posts",
    },
    {
      label: "My Account",
      slug: "my-account",
    },
    {
      label: "My Adress",
      slug: "my-adress",
    },
  ];

  const SidebarDivider = () => {
    return <div className="sidebar__divider "></div>;
  };
  const SidebarTitle = (props) => {
    return (
      <div className="sidebar__title" style={{ marginBottom: 5 }}>
        <span>{props.title}</span>
      </div>
    );
  };

  const SidebarList = (props) => {
    return (
      <ul className="sidebar__list">
        {props.data.map((item, index) => {
          return (
            <li
              onClick={() => navigation._navigate(`/account/${item.slug}`)}
              style={{
                color: section === item.slug ? color.accent : "white",
                fontWeight: section === item.slug ? "bold" : "regular",
              }}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    );
  };

  const Section = () => {
    switch (sectionActive) {
      case "my-vehicles":
        return <VehiculosAccount></VehiculosAccount>;
        break;
      case "my-orders":
        return <OrdersAccount></OrdersAccount>;
        break;

      case "my-account":
        return <Account></Account>;
        break;

      case "my-posts":
        return <AccountPost></AccountPost>;
        break;

      case "my-adress":
        return <Adress></Adress>;
        break;

      default:
        return null;
        break;
    }
  };

  const AuthCheck = () => {
    if (cliente._clienteLogged() !== false) {
      return (
        <Row>
          <Col xs={12} md={12} lg={3}>
            <div className="sidebar" style={{ marginBottom: 20 }}>
              <SidebarTitle title="Menu"></SidebarTitle>
              <SidebarList data={sections}></SidebarList>
            </div>
          </Col>
          <Col xs={12} md={12} lg={9}>
            <div className="account__header">
              <Section></Section>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <div className="user-check__false">
          <SectionTitle
            title="Account"
            caption="Access restricted"
          ></SectionTitle>
          <Alert variant={"danger"}>
            Please Sign in to access to Account section
          </Alert>
          <Button onClick={() => navigation._navigate("/login")} size="md">
            LOGIN
          </Button>
        </div>
      );
    }
  };

  const SectionsWrap = () => {
    switch (section) {
      case "My orders": {
        return <div>orders</div>;
        return null;
      }
    }
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <div className="account">
            <AuthCheck></AuthCheck>
          </div>
        </Container>
      </MainLayout>
    </div>
  );
};

export default AccountPage;

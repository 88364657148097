import React, { useCallback, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import Loader from "../../../components/web/Loader";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import { useQuery } from "react-query";
import Loading from "../../../components/web/Loading";
import Vehicle from "../../../components/web/Vehicle";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const API = new APICLASS();

const X3Models = () => {
  const _getx3Models = async () => {
    const { data } = await API.getX3Models();
    if (Array.isArray(data)) {
      return data;
    }
    return [];
  };

  const {
    data: vehiculos,
    isLoading,
    isError,
  } = useQuery("x3Models", _getx3Models);

  const ProductosCallBack = useCallback(() => {
    return (
      <div>
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <div className="section-wrap" style={{ marginTop: 0 }}>
            <SectionTitle
              title="Featured vehicles"
              caption="X3 Models"
            ></SectionTitle>

            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              dots={false}
            >
              {vehiculos.length > 0 ? (
                <>
                  {vehiculos.map((item, index) => {
                    return (
                      <>
                        {vehiculos.map((item, index) => {
                          return (
                            <Vehicle
                              key={index}
                              image={item.imagen}
                              name={item.nombre}
                              brand={item.marca}
                              id_categoria={item.id_categoria}
                              plazas={item.plazas}
                              id={item.id}
                              slug={item.slug}
                              full
                            ></Vehicle>
                          );
                        })}
                      </>
                    );
                  })}
                </>
              ) : null}
            </OwlCarousel>
          </div>
        )}
      </div>
    );
  }, [vehiculos]);

  return <ProductosCallBack></ProductosCallBack>;
};

export default X3Models;

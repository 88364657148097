import React, { useContext, useEffect } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import MainLayout from "../../../components/web/Layout/MainLayout";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import SideBarCategory from "./sidebar";
import StoreState from "../../../state/store";
import { Message, Tag } from "rsuite";
import UTVImage from "../../../assets/img/utv-icon-accent.png";
import APICLASS from "../../../api/web";
import useNavigation from "../../../hooks/useNavigation";
import { useStateIfMounted } from "use-state-if-mounted";
import _ from "lodash";
import Loading from "../../../components/web/Loading";
import {
  SideBar,
  SidebarDivider,
  SidebarTitle,
  SidebarList,
} from "../../../components/web/SideBar";

const API = new APICLASS();

const VehiclePage = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();

  const { vehiculoSeleccionado, setVehiculoSeleccionado, categorias } =
    useContext(StoreState);

  const [productos, setProductos] = useStateIfMounted([]);
  const [productosFiltrados, setProductosFiltrados] = useStateIfMounted([]);

  const [vehiculo, setVehiculo] = useStateIfMounted([]);
  const [categoryFilter, setCategoryFilter] = useStateIfMounted(false);

  const [loader, setLoader] = useStateIfMounted(true);

  const _filtrarPorCategoria = (item) => {
    setCategoryFilter(item);
    const filtrados = _.filter(productos, {
      producto: { id_categoria: item.id },
    });
    setProductosFiltrados(filtrados);
  };

  const _removeFilter = () => {
    setProductosFiltrados(productos);
    setCategoryFilter(false);
  };

  useEffect(() => {
    _getInfoVehiculo();
  }, []);

  const _getInfoVehiculo = async () => {
    const { data } = await API.getInfoCompletaVehiculo(params.id);
    if (data !== false) {
      setProductos(data.productos);
      setProductosFiltrados(data.productos);
      setVehiculo(data.vehiculo);
    }

    setLoader(false);
  };

  const Products = () => {
    return (
      <div>
        {categoryFilter !== false && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tag onClose={() => _removeFilter()} closable>
              {categoryFilter.nombre}
            </Tag>
          </div>
        )}

        <SectionTitle
          title={
            categoryFilter !== false
              ? `${vehiculo.nombre} (${categoryFilter.nombre})`
              : vehiculo.nombre
          }
          caption={vehiculo.marca}
        ></SectionTitle>

        {productosFiltrados.length > 0 ? (
          <div className="grid-flex animate__animated animate__fadeIn">
            {productosFiltrados.map((item, index) => {
              return (
                <ProductRow
                  key={index}
                  name={item.producto.nombre}
                  priceRegular={`${item.producto.precio}`}
                  priceSale={`${item.producto.precio_oferta}`}
                  image={item.portada}
                  id={item.producto.id}
                  slug={item.producto.slug}
                ></ProductRow>
              );
            })}
          </div>
        ) : (
          <Message
            type="warning"
            title="We couldn't find products"
            description={
              <p>
                {`We couldn't find products for ${vehiculo.nombre} at the momment`}{" "}
                {categoryFilter !== false
                  ? ` for ${categoryFilter.nombre} category.`
                  : "."}
                <br />
                {categoryFilter === false
                  ? "Please try using other vehicle."
                  : "Please try using other category."}
              </p>
            }
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={3}>
              <SideBar>
                <SidebarTitle title="Filter by category"></SidebarTitle>

                <SidebarList
                  actionItem={true}
                  actionItemFunction={_filtrarPorCategoria}
                  data={categorias}
                  categoriaActiva={categoryFilter}
                ></SidebarList>
              </SideBar>
            </Col>
            <Col xs={12} md={12} lg={9}>
              {loader ? (
                <Loading text="Loading vehicle data..."></Loading>
              ) : (
                <Products></Products>
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default VehiclePage;

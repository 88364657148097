import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StoreState from "../../../state/store";

import { useHistory } from "react-router-dom";
import APICLASS from "../../../api/web";
import MainLayout from "../../../components/web/Layout/MainLayout";
import CategoriesSlide from "./categories_slide";
import ProductosFeatured from "./featured";
import Hero from "./hero";
import ProductosNuevos from "./nuevos";
import Posts from "./posts";
import CatalogoBanner from "./catalogo_banner";
import X3Models from "./x3";

const API = new APICLASS();

const HomePage = (props) => {
  const { vehiculoSeleccionado, setVehiculoSeleccionado } =
    useContext(StoreState);
  const history = useHistory();

  return (
    <MainLayout>
      <Hero></Hero>
      <Container>
        <CategoriesSlide></CategoriesSlide>
        <Row>
          <Col>
            <ProductosNuevos></ProductosNuevos>
          </Col>
        </Row>
      </Container>
      <CatalogoBanner></CatalogoBanner>
      <Container>
        <Row>
          <Col>
            <Posts></Posts>
          </Col>
        </Row>

        <Row>
          <Col>
            <X3Models></X3Models>
          </Col>
        </Row>

        <Row>
          <Col>
            <ProductosFeatured></ProductosFeatured>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default HomePage;

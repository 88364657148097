import React, { useContext } from "react";
import StoreState from "../state/store";
import useNavigation from "./useNavigation";

const useCliente = () => {
  const navigation = useNavigation();

  const { cliente, setCliente, vehiculos, setVehiculos, cart, setCart } =
    useContext(StoreState);

  const _setCliente = (data) => {
    localStorage.setItem("cuid", data.uid);
    setCliente(data);
  };

  const _setVehiculos = (data) => {
    setVehiculos(data);
  };

  const _getVehiculos = () => {
    return vehiculos;
  };

  const _logOut = async (data) => {
    localStorage.removeItem("cuid");
    setCliente(false);
    localStorage.removeItem("cart");
    setCart([]);
    navigation._navigate("/home");
  };

  const _data = () => {
    return cliente;
  };

  const _clienteLogged = () => {
    if (cliente !== false) {
      return true;
    }

    return false;
  };

  return {
    _setCliente,
    _data,
    _clienteLogged,
    _logOut,
    _setVehiculos,
    _getVehiculos,
  };
};

export default useCliente;

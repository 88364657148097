import React, { useCallback, useContext, useEffect } from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import StoreState from "../../../state/store";
import { Button } from "react-bootstrap";
import color from "../../../constants/color";
import Avatar from "react-avatar";
import Logo from "../../../assets/img/logo.png";
import utvIcon from "../../../assets/img/utv-icon-accent.png";
import { useStateIfMounted } from "use-state-if-mounted";
import { Drawer, FlexboxGrid, Alert } from "rsuite";
import APICLASS from "../../../api/web";
import useCarrito from "../../../hooks/useCarrito";
import DrawerVehiculos from "../DrawerVehiculos";
import NumberFormat from "react-number-format";
import useNavigation from "../../../hooks/useNavigation";
import imagePath from "../../../constants/pathImages";
import Loading from "../Loading";
import { IoMenu, IoSearch } from "react-icons/io5";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";

const API = new APICLASS();

const TopBarWeb = (props) => {
  const navigation = useNavigation();
  const match = navigation._getMatch();

  const carrito = useCarrito();
  const {
    cliente,
    setCliente,
    vehiculos,
    setVehiculos,
    vehiculoSeleccionado,
    setVehiculoSeleccionado,
    drawerVehiculosToggle,
    setDrawerVehiculosToggle,
  } = useContext(StoreState);

  const [productosSearch, setProductosSearch] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");
  const [showSearch, setShowSearch] = useStateIfMounted(false);
  const [linkSeleccionado, setLinkSeleccionado] = useStateIfMounted(0);
  const [loaderSearch, setLoaderSearch] = useStateIfMounted(false);

  const links = [
    { link: "/", label: "HOME", routeName: "home" },
    { link: "/categories", label: "CATEGORIES", routeName: "categories" },
    { link: "/polaris", label: "POLARIS", routeName: "polaris" },
    { link: "/canam", label: "CANAM", routeName: "canam" },
    { link: "/contact", label: "CONTACT", routeName: "contact" },
  ];

  const _navigate = (link, index) => {
    setLinkSeleccionado(index);
    navigation._navigate(link);
  };

  useEffect(() => {
    if (search.length > 0) {
      setShowSearch(true);
      _search();
    } else {
      setShowSearch(false);
      setProductosSearch([]);
    }
  }, [search]);

  const _search = async () => {
    setLoaderSearch(true);
    const { data } = await API.getProductosSearch(search);
    if (data !== false) {
      if (Array.isArray(data)) {
        setProductosSearch(data);
      }
    } else {
      setProductosSearch([]);
    }
    setLoaderSearch(false);
  };

  const _goProduct = (item) => {
    setShowSearch(false);
    setSearch("");
    navigation._navigate(`/product/${item.producto.slug}/${item.producto.id}`);
  };

  const _clearSearch = () => {
    setSearch("");
    setShowSearch(false);
  };

  const _removeVehiculoSeleccionado = () => {
    setVehiculoSeleccionado(false);
    localStorage.removeItem("selectedVehicle");
    Alert.success(`Selected vehicle removed`);
  };

  const UserTop = useCallback(() => {
    return (
      <div
        className="top-bar-web__user"
        onClick={() => navigation._navigate("/account/my-account")}
      >
        <BiUserCircle size={21} color={color.accent}></BiUserCircle>

        <span className="top-bar-web__user__name">
          {`${cliente.nombre} ${cliente.apellidos}`}
        </span>
      </div>
    );
  }, [cliente]);

  const Cliente = (props) => {
    if (cliente === false) {
      //No logueado
      return (
        <div className="top-bar-web__no-user">
          <Button
            onClick={() => navigation._navigate("/login")}
            size="md"
            variant="link"
          >
            My account
          </Button>
        </div>
      );
    } else {
      return <UserTop></UserTop>;
    }
  };

  return (
    <>
      {/* ===== /MOBILE ===== */}
      <div className="top-bar-responsive">
        <div>
          <DrawerVehiculos></DrawerVehiculos>
          {/* menu mobile */}
          <div className="menu-mobile__logo">
            <img src={Logo}></img>
          </div>
          <div className="menu-mobile">
            <div>
              <Cliente></Cliente>
            </div>

            <div>
              <div
                className="top-bar-web__menu__cart--res"
                onClick={() => navigation._navigate("/cart")}
              >
                <div className="top-bar-web__menu__cart__icon">
                  <FiShoppingCart color={color.text} size={23}></FiShoppingCart>
                  <div className="total-items">{carrito._getItemsCount()}</div>
                </div>

                <div className="top-bar-web__menu__cart__total">
                  <NumberFormat
                    value={carrito._getTotal()}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={","}
                    decimalSeparator="."
                    renderText={(value, props) => (
                      <span {...props}>{value}</span>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /menu mobile */}

          {/* vehiculo seleccionado */}
          <div className="menu-mobile__vehiculo">
            <div
              className="menu-mobile__vehiculo__selected__false"
              onClick={() => setDrawerVehiculosToggle(true)}
            >
              <img src={utvIcon}></img>
              <span>Search vehicle</span>
            </div>
          </div>
          {/* vehiculo seleccionado */}

          <div className="top-bar-web__search--res">
            <FaSearch color={color.text}></FaSearch>
            <input
              value={search}
              onChange={(val) => setSearch(val.target.value)}
              placeholder="Search for products (name)"
              onBlur={() => {
                _clearSearch();
              }}
            ></input>

            {/* ===== SEARCH ===== */}
            {showSearch && (
              <div className="top-bar-web__search__result">
                {loaderSearch ? (
                  <Loading text="Loading data..."></Loading>
                ) : (
                  <>
                    {productosSearch.length > 0 ? (
                      <ul>
                        {productosSearch.map((item, index) => {
                          return (
                            <li
                              onClick={() => _goProduct(item)}
                              className="top-bar-web__search__result__item"
                            >
                              <div
                                className="top-bar-web__search__result__item__img"
                                style={{
                                  backgroundImage: `url(${
                                    imagePath + item.portada
                                  })`,
                                }}
                              ></div>
                              <div className="top-bar-web__search__result__item__info">
                                <div>
                                  <h6>{item.producto.nombre}</h6>
                                  <p>{item.producto.categoria}</p>
                                </div>
                                <div className="top-bar-web__search__result__item__info__price">
                                  {item.producto.precio !== "null" ? (
                                    <h6>${item.producto.precio} USD</h6>
                                  ) : (
                                    <h6>${item.producto.precio_oferta} USD</h6>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <div className="top-bar-web__search__result__empty">
                        {search.length > 0 ? (
                          <div>No results</div>
                        ) : (
                          <div>What are you looking for?</div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* ===== /MOBILE ===== */}

      <div className="top-bar-web-wrap top-bar-desk">
        <DrawerVehiculos></DrawerVehiculos>
        <div className="top-bar-web">
          <div className="top-bar-web__vehicle-selected">
            <div
              className="top-bar-web__vehicle-selected__false"
              onClick={() => setDrawerVehiculosToggle(true)}
            >
              <img src={utvIcon}></img>
              <span>Search vehicle</span>
            </div>
          </div>
          <div className="top-bar-web__search">
            <FaSearch color={color.text}></FaSearch>
            <input
              value={search}
              onChange={(val) => setSearch(val.target.value)}
              placeholder="Search for products (name)"
            ></input>
            {showSearch ? (
              <AiOutlineCloseCircle
                style={{ cursor: "pointer" }}
                size={19}
                onClick={() => _clearSearch()}
              >
                cerrar
              </AiOutlineCloseCircle>
            ) : null}

            {/* ===== SEARCH ===== */}
            {showSearch && (
              <div className="top-bar-web__search__result">
                {loaderSearch ? (
                  <Loading text="Loading data..."></Loading>
                ) : (
                  <>
                    {productosSearch.length > 0 ? (
                      <ul>
                        {productosSearch.map((item, index) => {
                          return (
                            <li
                              onClick={() => _goProduct(item)}
                              className="top-bar-web__search__result__item"
                            >
                              <div
                                className="top-bar-web__search__result__item__img"
                                style={{
                                  backgroundImage: `url(${
                                    imagePath + item.portada
                                  })`,
                                }}
                              ></div>
                              <div className="top-bar-web__search__result__item__info">
                                <div>
                                  <h6>{item.producto.nombre}</h6>
                                  <p>{item.producto.categoria}</p>
                                </div>
                                <div className="top-bar-web__search__result__item__info__price">
                                  {item.producto.precio !== "null" ? (
                                    <h6>${item.producto.precio} USD</h6>
                                  ) : (
                                    <h6>${item.producto.precio_oferta} USD</h6>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <div className="top-bar-web__search__result__empty">
                        {search.length > 0 ? (
                          <div>No results</div>
                        ) : (
                          <div>What are you looking for?</div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {/* ===== /SEARCH ===== */}

          <Cliente></Cliente>
        </div>

        <div className="top-bar-web__menu">
          <div
            className="top-bar-web__menu__logo"
            onClick={() => navigation._navigate("/")}
          >
            <img src={Logo}></img>
          </div>
          <div className="top-bar-web__menu__nav">
            <nav>
              <ul>
                {links.map((item, index) => {
                  return (
                    <li
                      onClick={() => {
                        _navigate(item.link, index);
                      }}
                    >
                      <span
                        style={{
                          color:
                            linkSeleccionado == index ? "white" : color.text,
                          background:
                            linkSeleccionado == index
                              ? color.accent
                              : "transparent",

                          fontWeight:
                            linkSeleccionado == index ? "bold" : "600",

                          padding: "10px",
                        }}
                      >
                        {item.label}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div
            className="top-bar-web__menu__cart"
            onClick={() => navigation._navigate("/cart")}
          >
            <div className="top-bar-web__menu__cart__icon">
              <FiShoppingCart color={color.text} size={23}></FiShoppingCart>
              <div className="total-items">{carrito._getItemsCount()}</div>
            </div>

            <div className="top-bar-web__menu__cart__total">
              <NumberFormat
                value={carrito._getTotal()}
                displayType={"text"}
                prefix={"$"}
                thousandSeparator={","}
                decimalSeparator="."
                renderText={(value, props) => <span {...props}>{value}</span>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBarWeb;

import React from "react";
import { Loader as LoaderRS } from "rsuite";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoaderRS></LoaderRS>
      <span style={{ paddingLeft: 5 }}> Loading...</span>
    </div>
  );
};

export default Loader;

import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import Loader from "../../../components/web/Loader";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import { useQuery } from "react-query";
import Loading from "../../../components/web/Loading";

const API = new APICLASS();

const ProductosFeatured = () => {
  const _getFeatured = async () => {
    const { data } = await API.getProductosFeatured();
    if (Array.isArray(data)) {
      return data;
    }
    return [];
  };

  const {
    data: productos,
    isLoading,
    isError,
  } = useQuery("productosFeatured", _getFeatured);

  return (
    <div>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="section-wrap" style={{ marginTop: 20 }}>
          <SectionTitle
            title="Featured Products"
            caption="Most selled products"
          ></SectionTitle>

          {productos.length > 0 ? (
            <div>
              <div className="grid-flex">
                {productos.map((item, index) => {
                  return (
                    <ProductRow
                      key={index}
                      name={item.producto.nombre}
                      priceRegular={`${item.producto.precio}`}
                      priceSale={`${item.producto.precio_oferta}`}
                      image={item.portada}
                      id={item.producto.id}
                      slug={item.producto.slug}
                    ></ProductRow>
                  );
                })}
              </div>
            </div>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductosFeatured;

import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import MainLayout from "../../../components/web/Layout/MainLayout";
import color from "../../../constants/color";
import useNavigation from "../../../hooks/useNavigation";
import StoreState from "../../../state/store";

const CheckOutConfirmationPage = (props) => {
  const history = useHistory();
  const params = useParams();
  const navigation = useNavigation();
  const { cart, cliente } = useContext(StoreState);

  return (
    <div>
      <MainLayout margin>
        <Container>
          {cliente !== false ? (
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BsCheckCircle size={90} color={color.accent}></BsCheckCircle>

                  <span style={{ fontSize: 23, color: color.text }}>
                    {`Your order #${params.uid} has been confirmed`}.
                  </span>

                  <Button
                    onClick={() => navigation._navigate("/account/my-orders")}
                    style={{ marginTop: 20 }}
                  >
                    SEE MY ORDERS
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BsCheckCircle size={90} color={color.accent}></BsCheckCircle>

                  <span style={{ fontSize: 23, color: color.text }}>
                    {`Your order #${params.uid} has been confirmed, please check your email for more details`}
                    .
                  </span>

                  <Button
                    onClick={() => navigation._navigate("/")}
                    style={{ marginTop: 20 }}
                  >
                    Continue buying
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </MainLayout>
    </div>
  );
};

export default CheckOutConfirmationPage;

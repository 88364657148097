import React, { useContext, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FiShoppingCart } from "react-icons/fi";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import APICLASS from "../../../api/web";
import APIWORLDCLASS from "../../../api/world";
import MainLayout from "../../../components/web/Layout/MainLayout";
import Loading from "../../../components/web/Loading";
import color from "../../../constants/color";
import useCarrito from "../../../hooks/useCarrito";
import StoreState from "../../../state/store";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Button, Checkbox, Input, Message, SelectPicker } from "rsuite";

const API = new APICLASS();
const APIWORLD = new APIWORLDCLASS();

const CheckOutPage = (props) => {
  const history = useHistory();
  const carrito = useCarrito();
  const { cart, cliente } = useContext(StoreState);

  const [useDifferentAdress, setUseDifferentAddress] = useStateIfMounted(false);
  const [hideSelectCities, setHideSelectCities] = useStateIfMounted(false);

  const [loaderVenta, setLoaderVenta] = useStateIfMounted(false);
  const [pago, setPago] = useStateIfMounted(false);
  const [loaderOperacion, setLoaderOperacion] = useStateIfMounted(false);
  const [loaderOperacionEstatus, setLoaderOperacionEstatus] =
    useStateIfMounted("Cobrando");

  const [name, setName] = useStateIfMounted(
    cliente.nombre === null ? "" : cliente.nombre
  );
  const [confirmAdress, setConfirmAdress] = useStateIfMounted(false);
  const [email, setEmail] = useStateIfMounted(cliente.email);
  const [lastName, setLastName] = useStateIfMounted(
    cliente.apellidos === null ? "" : cliente.apellidos
  );

  const [company, setCompany] = useStateIfMounted(
    cliente.empresa === null ? "" : cliente.empresa
  );
  const [street, setStreet] = useStateIfMounted(
    cliente.direccion_calle === null ? "" : cliente.direccion_calle
  );
  const [cityID, setCityID] = useStateIfMounted(
    cliente.id_ciudad === null
      ? ""
      : {
          id: cliente.id_ciudad,
          name: cliente.ciudad,
        }
  );
  const [stateID, setStateID] = useStateIfMounted(
    cliente.id_state === "0"
      ? ""
      : {
          id: cliente.id_state,
          name: cliente.estado,
        }
  );

  const [zip, setZip] = useStateIfMounted(
    cliente.zip === "0" ? "" : cliente.zip
  );
  const [countryID, setCountryID] = useStateIfMounted(
    cliente.id_pais === "0"
      ? ""
      : {
          id: cliente.id_pais,
          name: cliente.pais,
        }
  );

  const [ciudad, setCiudad] = useStateIfMounted(
    cliente.ciudad === null ? "" : cliente.ciudad
  );

  const [pais, setPais] = useStateIfMounted(
    cliente.pais === null ? "" : cliente.pais
  );

  const [estado, setEstado] = useStateIfMounted(
    cliente.estado === null ? "" : cliente.estado
  );

  const [paises, setPaises] = useStateIfMounted([]);
  const [estados, setEstados] = useStateIfMounted([]);
  const [ciudades, setCiudades] = useStateIfMounted([]);

  const [phone, setPhone] = useStateIfMounted(
    cliente.telefono === null ? "" : cliente.telefono
  );
  const [adressXtra, setAdressXtra] = useStateIfMounted(
    cliente.zip === null ? "" : cliente.direccion_extra
  );
  const [loaderPaises, setLoaderPaises] = useStateIfMounted(true);

  // ===== DATOS DEL CLIENTE ======
  useEffect(() => {
    if (useDifferentAdress) {
      _usarDiferentesDatos();
    } else {
      _actualizarDatosCliente();
    }
  }, [useDifferentAdress]);

  const _setDatosUbicacion = async () => {
    setCountryID(
      cliente !== false
        ? cliente.id_pais == "0"
          ? ""
          : {
              id: cliente.id_pais,
              name: cliente.pais,
            }
        : ""
    );
    setPais(cliente.pais === null ? "" : cliente.pais);

    const { data } = await APIWORLD.getEstadosDelPais(cliente.id_pais);
    if (data !== false) {
      const estadosArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { id: item.id, name: item.name },
        };

        estadosArr.push(itemData);
      });

      setEstados(estadosArr);

      setStateID(
        cliente.id_state === "0"
          ? ""
          : {
              id: cliente.id_state,
              name: cliente.estado,
            }
      );
      setEstado(cliente.estado === null ? "" : cliente.estado);

      if (cliente.id_state === "1407") {
        carrito._agregarImpuestos();
      } else {
        carrito._quitarImpuestos();
      }

      const dataCity = await APIWORLD.getCiudadesDelEstado(stateID.id);
      if (dataCity.data !== false) {
        const ciudadesArr = [];
        dataCity.data.map((item) => {
          let itemCityData = {
            label: item.name,
            value: { name: item.name, id: item.id },
          };

          ciudadesArr.push(itemCityData);
        });

        setCiudades(ciudadesArr);
      }

      setCiudad(cliente.ciudad === null ? "" : cliente.ciudad);

      setCityID(
        cliente.id_ciudad === null
          ? ""
          : {
              id: cliente.id_ciudad,
              name: cliente.ciudad,
            }
      );
    }
  };
  const _actualizarDatosCliente = () => {
    setName(cliente.nombre === null ? "" : cliente.nombre);
    setLastName(cliente.apellidos === null ? "" : cliente.apellidos);
    setEmail(cliente.email);
    setPhone(cliente.telefono === null ? "" : cliente.telefono);

    _setDatosUbicacion();

    setCompany(cliente.empresa === null ? "" : cliente.empresa);

    setZip(cliente.zip === "0" ? "" : cliente.zip);
    setAdressXtra(
      cliente.direccion_extra === null ? "" : cliente.direccion_extra
    );
    setStreet(cliente.direccion_calle === null ? "" : cliente.direccion_calle);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     _setEstado();
  //   }, 10000);
  // }, [countryID]);

  useEffect(() => {
    if (cliente !== false) {
      _actualizarDatosCliente();
    }
  }, [cliente]);

  const _usarDiferentesDatos = () => {
    setName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCiudad("");
    setCityID("");
    setStateID("");
    setEstado("");
    setCompany("");
    setCountryID("");
    setPais("");
    setZip("");
    setAdressXtra("");
    setStreet("");
  };

  // ===== PAYPAL =====
  const initialOptions = {
    "client-id": "sb",
    currency: "USD",
    intent: "capture",
  };

  const _createOrder = (data, actions) => {
    try {
      const itemsCarrito = carrito._getItems();
      const orderItems = [];
      itemsCarrito.map((item) => {
        let orderItem = {
          name: item.nombre,
          quantity: item.cantidad,
          description: item.nombre,
          sku: item.sku,
          category: "PHYSICAL_GOODS",
          unit_amount: {
            currency_code: "USD",
            value: item.precioCobrar,
          },
        };

        orderItems.push(orderItem);
      });

      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: carrito._getTotalOrder(),
              currency_code: "USD",
              breakdown: {
                item_total: {
                  value: carrito._getTotal(),
                  currency_code: "USD",
                },
                tax_total: {
                  currency_code: "USD",
                  value: carrito._getImpuestos(),
                },
                shipping: {
                  currency_code: "USD",
                  value: carrito._getTotalShipping(),
                },
              },
            },
            description: "DMX PERFORMANCE BUY",
            items: orderItems,
          },
        ],
      });
    } catch (e) {
      console.info(e);
    }
  };

  const _onApprove = async (data, actions) => {
    const pagoData = await actions.order.capture();
    _saveVenta(pagoData);
  };

  const _onCancel = (data) => {};

  const _onError = (data) => {
    history.push("/check-out/error");
  };

  // ===== VENTA ======
  const _saveVenta = async (pagoData) => {
    setLoaderVenta(true);
    const request = {
      telefono: phone,
      direccion_calle: street,
      direccion_extra: adressXtra,
      ciudad: ciudad,
      id_cliente: cliente != false ? cliente.id : 0,
      zip: zip,
      email: email,
      total: carrito._getTotalOrderParsed(),
      nombre: name,
      apellidos: lastName,
      envio_total: carrito._getTotalShippingParsed(),
      productos: carrito._getItems(),
      pago: pagoData,
      estado: estado,
      pais: pais,
      impuestos: carrito._getImpuestosParsed(),
      subtotal: carrito._getTotalParsed(),
      descuento_seller_total: carrito._getTotalDescuentoResellerParsed(),
      descuento_seller: cliente.descuento_seller,
    };

    const { data } = await API.saveVenta(request);

    if (data.status == true) {
      console.log("entrando a bien");
      carrito._cleanCart();
      history.push(`/check-out/confirm/${data.uid}`, { uid: data.uid });
    }

    setLoaderVenta(false);
  };

  const _confirmAdress = () => {
    setConfirmAdress(true);
  };

  // ===== WORLD API =====

  useEffect(() => {
    _getPaises();
  }, []);

  const _getPaises = async () => {
    const { data } = await APIWORLD.getPaises();
    if (data !== false) {
      const paisesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        paisesArr.push(itemData);
      });

      setPaises(paisesArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (countryID !== false || countryID !== null) {
      _getEstados();
    }
  }, [countryID]);

  const _getEstados = async () => {
    setLoaderPaises(true);
    setEstados([]);
    setCiudades([]);
    setStateID("");
    setCityID("");
    if (countryID === "") {
      setStateID("");
      setCityID("");
    }

    const { data } = await APIWORLD.getEstadosDelPais(countryID.id);
    if (data !== false) {
      const estadosArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { id: item.id, name: item.name },
        };

        estadosArr.push(itemData);
      });

      setEstados(estadosArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (stateID !== false || stateID !== null) {
      _getCiudades();
    }
  }, [stateID]);

  const _getCiudades = async () => {
    setLoaderPaises(true);
    const { data } = await APIWORLD.getCiudadesDelEstado(stateID.id);
    if (data !== false) {
      const ciudadesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        ciudadesArr.push(itemData);
      });

      setCiudades(ciudadesArr);
    }
    setLoaderPaises(false);
  };

  const _setPais = (item) => {
    setPais(item.name);
    setCountryID(item);
  };

  const _setCiudad = (item) => {
    setCiudad(item.name);
    setCityID(item);
  };

  const _setEstado = async (item = false) => {
    const _getEstadosLocal = await _getEstados();

    if (estados.length > 0) {
      if (item !== false) {
        setEstado(item.name);
        setStateID(item);

        if (item.id === "1407") {
          carrito._agregarImpuestos();
        } else {
          carrito._quitarImpuestos();
        }
      } else {
        setStateID(
          cliente.id_state === "0"
            ? ""
            : {
                id: cliente.id_state,
                name: cliente.estado,
              }
        );
        setEstado(cliente.estado === null ? "" : cliente.estado);
      }
    }
  };

  const _validarMostrarResumen = () => {
    if (
      name != "" &&
      lastName != "" &&
      email != "" &&
      phone != "" &&
      countryID != "" &&
      zip != undefined &&
      street != undefined &&
      ciudad != undefined &&
      stateID != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          {carrito._getItems().length > 0 ? (
            <Row>
              <Col xs={12} md={8}>
                {/* ===== DIRECCIÓN ====== */}
                <div>
                  {loaderPaises ? (
                    <Loading text="Loading data..."></Loading>
                  ) : (
                    <div className="check-out">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ color: "white" }}>BILLING DETAILS</h4>
                      </div>

                      {confirmAdress ? (
                        <>
                          <div>Name: {name}</div>
                          <div>Last Name: {lastName}</div>
                          <div>Country: {pais}</div>
                          <div>State: {estado}</div>
                          <div>Ciudad: {ciudad}</div>
                          <div>ZIP: {zip}</div>
                          <div>Street: {street}</div>
                          <div>Phone: {phone}</div>
                          <div>Email: {email}</div>

                          <Button
                            style={{ marginTop: 20 }}
                            onClick={() => setConfirmAdress(false)}
                          >
                            Edit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs={12}>
                              <Form>
                                <Row>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Name
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={name}
                                        onChange={(val) => setName(val)}
                                        placeholder="Name"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>

                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Last Name
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={lastName}
                                        onChange={(val) => setLastName(val)}
                                        placeholder="Last Name"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Phone number
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={phone}
                                        onChange={(val) => setPhone(val)}
                                        placeholder="Phone number"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>

                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Email
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={email}
                                        onChange={(val) => setEmail(val)}
                                        placeholder="Email"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Country{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <SelectPicker
                                        searchable
                                        data={paises}
                                        appearance="default"
                                        placeholder="Select the coutry"
                                        cleanable={false}
                                        value={countryID}
                                        onChange={(val) => _setPais(val)}
                                        style={{ width: "100%" }}
                                        disabled={confirmAdress}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        State{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <SelectPicker
                                        data={estados}
                                        appearance="default"
                                        placeholder={
                                          countryID !== ""
                                            ? "Select the state"
                                            : "Select the country first"
                                        }
                                        cleanable={false}
                                        value={stateID}
                                        onChange={(val) => _setEstado(val)}
                                        searchable={true}
                                        style={{ width: "100%" }}
                                        disabled={
                                          countryID !== "" ? false : true
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  {hideSelectCities ? (
                                    <Col xs={12} md={6}>
                                      <div className="form__item">
                                        <span className="form__item__label">
                                          Town/City{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                        <Input
                                          value={ciudad}
                                          onChange={(val) => setCiudad(val)}
                                          placeholder="Ciudad"
                                        ></Input>
                                      </div>
                                    </Col>
                                  ) : (
                                    <Col xs={12} md={6}>
                                      <div className="form__item">
                                        <span className="form__item__label">
                                          Town/City
                                        </span>
                                        <SelectPicker
                                          searchable
                                          data={ciudades}
                                          appearance="default"
                                          placeholder={
                                            stateID !== ""
                                              ? "Select the city"
                                              : "Select the state first"
                                          }
                                          cleanable={false}
                                          value={cityID}
                                          onChange={(val) => _setCiudad(val)}
                                          style={{ width: "100%" }}
                                          disabled={
                                            stateID !== "" ? false : true
                                          }
                                        />
                                        <Checkbox
                                          defaultChecked={
                                            hideSelectCities ? true : false
                                          }
                                          onChange={() =>
                                            setHideSelectCities(
                                              !hideSelectCities
                                            )
                                          }
                                        >
                                          {" "}
                                          I can't find my city
                                        </Checkbox>
                                      </div>
                                    </Col>
                                  )}

                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Street Adress{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={street}
                                        onChange={(val) => setStreet(val)}
                                        placeholder="Street adress"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Apartment, Suite, Unit, etc.
                                      </span>
                                      <Input
                                        value={adressXtra}
                                        onChange={(val) => setAdressXtra(val)}
                                        placeholder="Extra info adress"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <div className="form__item">
                                      <span className="form__item__label">
                                        Post Code / ZIP{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                      <Input
                                        value={zip}
                                        onChange={(val) => setZip(val)}
                                        placeholder="Post code / zip"
                                        disabled={confirmAdress}
                                      ></Input>
                                    </div>
                                  </Col>
                                </Row>

                                {!confirmAdress && (
                                  <div>
                                    {_validarMostrarResumen() ? (
                                      <Button
                                        onClick={() => _confirmAdress()}
                                        style={{
                                          background: color.secondary,
                                          fontWeight: "bold",
                                          color: color.text,
                                        }}
                                      >
                                        CONFIRM ADRESS
                                      </Button>
                                    ) : (
                                      <span style={{ color: color.secondary }}>
                                        Fill the required fields
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Form>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {/* ===== /DIRECCIÓN ====== */}

                <div></div>
              </Col>
              <Col xs={12} md={4}>
                <div className="check-out__recap">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>ORDER RECAP</h4>
                  </div>

                  <div className="check-out__items">
                    <h6>Items</h6>

                    {carrito._getItemsCount() > 0 ? (
                      <div>
                        {carrito._getItems().map((item, index) => {
                          return (
                            <div className="check-out__item">
                              <span className="check-out__item__info">
                                <FiShoppingCart
                                  style={{ marginRight: 5 }}
                                  color={color.secondary}
                                ></FiShoppingCart>
                                {item.nombre} ({item.cantidad})
                              </span>
                              <span className="check-out__item__price">
                                <NumberFormat
                                  value={item.precioCobrar}
                                  displayType={"text"}
                                  prefix={"$"}
                                  thousandSeparator={","}
                                  decimalSeparator="."
                                  renderText={(value, props) => (
                                    <span {...props}>{value}</span>
                                  )}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div className="check-out__subTotal">
                    <div className="check-out__subTotal__caption">
                      <span>Sub total</span>
                    </div>
                    <div className="check-out__subTotal__price">
                      <NumberFormat
                        value={carrito._getTotal()}
                        displayType={"text"}
                        prefix={"$"}
                        thousandSeparator={","}
                        decimalSeparator="."
                        renderText={(value, props) => (
                          <span {...props}>{value}</span>
                        )}
                      />
                    </div>
                  </div>

                  {countryID.id !== undefined && countryID.id === "233" ? (
                    <div>
                      <div className="check-out__subTotal">
                        <div className="check-out__subTotal__caption">
                          <span>Shipping cost</span>
                        </div>
                        <div className="check-out__subTotal__price">
                          <NumberFormat
                            value={carrito._getTotalShipping()}
                            displayType={"text"}
                            prefix={"$"}
                            thousandSeparator={","}
                            decimalSeparator="."
                            renderText={(value, props) => (
                              <span {...props}>{value}</span>
                            )}
                          />
                        </div>
                      </div>

                      <div className="check-out__subTotal">
                        <div className="check-out__subTotal__caption">
                          <span>Tax</span>
                        </div>
                        <div className="check-out__subTotal__price">
                          <NumberFormat
                            value={carrito._getImpuestos()}
                            displayType={"text"}
                            prefix={"$"}
                            thousandSeparator={","}
                            decimalSeparator="."
                            renderText={(value, props) => (
                              <span {...props}>{value}</span>
                            )}
                          />
                        </div>
                      </div>

                      {cliente.seller == 1 && (
                        <div className="check-out__subTotal">
                          <div className="check-out__subTotal__caption">
                            <span style={{ color: "red" }}>
                              Seller Disccount{" "}
                              {parseFloat(cliente.descuento_seller) * 100}%
                            </span>
                          </div>
                          <div className="check-out__subTotal__price">
                            <NumberFormat
                              value={carrito._getTotalDescuentoReseller()}
                              displayType={"text"}
                              prefix={"$"}
                              thousandSeparator={","}
                              decimalSeparator="."
                              renderText={(value, props) => (
                                <span {...props}>{value}</span>
                              )}
                            />
                          </div>
                        </div>
                      )}

                      <div className="check-out__shipping">
                        <div className="check-out__total">
                          <div className="check-out__total__caption">
                            <span style={{ fontWeight: "bold" }}>Total</span>
                          </div>

                          <div className="check-out__total__price">
                            <NumberFormat
                              value={carrito._getTotalOrder()}
                              displayType={"text"}
                              prefix={"$"}
                              thousandSeparator={","}
                              decimalSeparator="."
                              renderText={(value, props) => (
                                <span {...props}>{value}</span>
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {confirmAdress ? (
                        <div
                          style={{ width: "100%" }}
                          className="animate__animated animate__fadeIn"
                        >
                          {loaderVenta ? (
                            <Loading text={"Loading..."}></Loading>
                          ) : (
                            <PayPalScriptProvider options={initialOptions}>
                              <PayPalButtons
                                createOrder={(data, actions) =>
                                  _createOrder(data, actions)
                                }
                                onApprove={(data, actions) =>
                                  _onApprove(data, actions)
                                }
                                onCancel={(data) => _onCancel(data)}
                                onError={(data) => _onError(data)}
                                style={{ layout: "horizontal" }}
                              />
                            </PayPalScriptProvider>
                          )}
                        </div>
                      ) : (
                        <div role="alert" class=" alert alert-danger show">
                          To continue, please confirm the address
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="check-out__subTotal">
                        <div className="check-out__subTotal__caption">
                          <span>Shipping cost</span>
                        </div>
                        <div className="check-out__subTotal__price">
                          <span>Not Available</span>
                        </div>
                      </div>

                      <div className="check-out__shipping">
                        <div className="check-out__total">
                          <div className="check-out__total__price">
                            contact us for a Quote (orders@dmxperformance.com)
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <Message
                  type="warning"
                  title="Warning"
                  description={
                    <p>
                      Please add some products to cart
                      <br />
                      <Button onClick={() => history.push("/")}>
                        Go to start
                      </Button>
                    </p>
                  }
                />
              </Col>
            </Row>
          )}
        </Container>
      </MainLayout>
    </div>
  );
};

export default CheckOutPage;

import React from "react";
import color from "../../../constants/color";

export const SideBar = (props) => {
  return <div className="sidebar">{props.children}</div>;
};

export const SidebarDivider = () => {
  return <div className="sidebar__divider "></div>;
};

export const SidebarTitle = (props) => {
  return (
    <div className="sidebar__title" style={{ marginBottom: 5 }}>
      <span>{props.title}</span>
    </div>
  );
};

export const SidebarList = (props) => {
  return (
    <ul className="sidebar__list">
      {props.data.map((item, index) => {
        return (
          <li
            key={index}
            onClick={() =>
              props.actionItem ? props.actionItemFunction(item) : null
            }
            style={{
              color:
                props.categoriaActiva !== false &&
                props.categoriaActiva.id === item.id
                  ? color.accent
                  : "white",
            }}
          >
            {item.nombre}
          </li>
        );
      })}
    </ul>
  );
};

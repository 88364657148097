import React, { useCallback, useEffect, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Badge,
  Tabs,
  Tab,
  Sonnet,
} from "react-bootstrap";
import { SelectPicker, Input, Button, ButtonGroup } from "rsuite";
import MainLayout from "../../../components/web/Layout/MainLayout";
import ProductRow from "../../../components/web/ProductRow";
import SectionTitle from "../../../components/web/sectionTitle";
import ImageGallery from "react-image-gallery";
import PostRow from "../../../components/web/postRow";
import APICLASS from "../../../api/web";
import filePath from "../../../constants/pathImages";
import useNavigation from "../../../hooks/useNavigation";
import { useStateIfMounted } from "use-state-if-mounted";
import Loading from "../../../components/web/Loading";
import color from "../../../constants/color";
import useCarrito from "../../../hooks/useCarrito";
import Vehicle from "../../../components/web/Vehicle";
import ImageDefault from "../../../assets/img/default.jpg";
import ImageColors from "../../../assets/img/colores.png";

const API = new APICLASS();

const ProductPage = (props) => {
  const carrito = useCarrito();
  const navigation = useNavigation();
  const params = navigation._getParam();
  const [galeria, setGaleria] = useStateIfMounted([]);
  const [vehiculosComptatibles, setVehiculosComptatibles] = useStateIfMounted(
    []
  );

  const [productosRecomendaciones, setProductosRecomendaciones] =
    useStateIfMounted([]);

  const [loader, setLoader] = useStateIfMounted(true);

  const [tab, setTab] = useStateIfMounted("description");
  const [comments, setComments] = useStateIfMounted("");

  const [colores, setColores] = useStateIfMounted([]);
  const [colorSeleccionado, setColorSeleccionado] = useStateIfMounted("");
  const [quantity, setQuantity] = useStateIfMounted(1);
  const [portada, setPortada] = useStateIfMounted("");
  const [vehiculo, setVehiculo] = useStateIfMounted("");
  const [optionSelected, setOptionSelected] = useStateIfMounted("");

  const [producto, setProducto] = useStateIfMounted(false);

  const Recommended = () => {
    if (productosRecomendaciones.length > 0) {
      return (
        <div className="section-wrap" style={{ marginTop: 20 }}>
          <SectionTitle
            title="Recommended accessories"
            caption={`${producto.categoria} category`}
          ></SectionTitle>
          <div className="grid-flex">
            {productosRecomendaciones.map((item, index) => {
              return (
                <ProductRow
                  key={index}
                  name={item.producto.nombre}
                  priceRegular={`${item.producto.precio}`}
                  priceSale={`${item.producto.precio_oferta}`}
                  image={item.portada}
                  id={item.producto.id}
                  slug={item.producto.slug}
                ></ProductRow>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="section-wrap" style={{ marginTop: 20 }}>
          <SectionTitle
            title="Recommended accessories"
            caption={`${producto.categoria} category`}
            action
            actionText="See more"
          ></SectionTitle>
          <div>sin accesorios</div>
        </div>
      );
    }
  };

  useEffect(() => {
    _getDetalle();
    _getColores();
  }, []);

  useEffect(() => {
    _getDetalle();
  }, [params.id]);

  const _getDetalle = async () => {
    const { data } = await API.getDetalleProducto(params.id);
    setGaleria([]);
    if (data !== false) {
      setProducto(data.producto);
      setPortada(data.portada);
      setVehiculosComptatibles(data.vehiculos);

      if (data.galeria.length > 0) {
        const galeria_arr = [];
        data.galeria.map((item, index) => {
          galeria_arr.push({
            original: `${filePath}/${item.url}`,
            thumbnail: `${filePath}/${item.url}`,
          });
        });

        setGaleria(galeria_arr);
      }
    }

    setLoader(false);
  };

  const _getColores = async () => {
    const { data } = await API.getColores();

    if (data.length > 0) {
      const colores_arr = [];
      data.map((item, index) => {
        colores_arr.push({
          label: item.nombre,
          value: item.nombre,
        });
      });

      setColores(colores_arr);
    }
  };

  const optionsTreeKickers = [
    {
      label: '72"',
      value: '72"',
    },
    {
      label: "74rc64",
      value: "74rc64",
    },
    {
      label: "72rc72",
      value: "72rc72",
    },
  ];

  useEffect(() => {
    if (producto !== false) {
      _getProductosRecomendados();
    }
  }, [producto]);

  const _getProductosRecomendados = async () => {
    const { data } = await API.getRecomendacionesProductosCategoria(
      producto.id_categoria
    );

    if (data !== false) {
      setProductosRecomendaciones(data);
    }
  };

  const _addToCart = () => {
    carrito._addToCart(
      producto,
      quantity,
      portada,
      producto.precio_oferta === null || undefined ? false : true,
      producto.precio_oferta === null || undefined
        ? producto.precio
        : producto.precio_oferta,
      null,
      colorSeleccionado,
      comments,
      vehiculo,
      optionSelected
    );

    _cleanForm();
  };

  const _cleanForm = () => {
    setQuantity(1);
    setColorSeleccionado("");
    setComments("");
    setVehiculo("");
  };

  const _validateAddCart = () => {
    if (colorSeleccionado == "" || vehiculo == "") {
      return false;
    }

    if (producto.id_categoria == 31 && optionSelected == "") {
      return false;
    }

    return true;
  };

  const _validateText = () => {
    if (colorSeleccionado == "") {
      return "Please choose a color *";
    }

    if (vehiculo == "") {
      return "Please type the vehicle *";
    }

    if (producto.id_categoria == 31 && optionSelected == "") {
      return "Please select a option *";
    }

    return true;
  };

  const ProductGallery = useCallback(() => {
    return (
      <div>
        {galeria.length > 0 ? (
          <ImageGallery items={galeria} />
        ) : (
          <div>
            <img style={{ width: "100%" }} src={ImageDefault}></img>
          </div>
        )}
      </div>
    );
  }, [galeria]);

  const ProductTabs = () => {
    return (
      <div className="tabs__wrap">
        <div className="tabs__header">
          <ButtonGroup>
            <Button
              appearance={tab !== "description" ? "ghost" : "primary"}
              color={tab === "description" ? "orange" : "orange"}
              onClick={() => setTab("description")}
            >
              Description
            </Button>
            <Button
              appearance={tab !== "vehicles" ? "ghost" : "primary"}
              color={tab === "vehicles" ? "orange" : "orange"}
              onClick={() => setTab("vehicles")}
            >
              Compatible Vehicles
            </Button>
            <Button
              appearance={tab !== "shipping" ? "ghost" : "primary"}
              color={tab === "shipping" ? "orange" : "orange"}
              onClick={() => setTab("shipping")}
            >
              Shipping
            </Button>
            <Button
              appearance={tab !== "colors" ? "ghost" : "primary"}
              color={tab === "colors" ? "orange" : "orange"}
              onClick={() => setTab("colors")}
            >
              Available Colors
            </Button>
          </ButtonGroup>
        </div>
        <div className="tab__content">
          {tab === "description" && (
            <div>
              <h2>Description</h2>
              {producto.descripcion !== null ? (
                <p>{producto.descripcion}</p>
              ) : (
                <div>Description not specified</div>
              )}
            </div>
          )}

          {tab === "vehicles" && (
            <div>
              <h2>Compatible Vehicles</h2>
              <div className="grid-flex">
                {vehiculosComptatibles !== false &&
                vehiculosComptatibles.length > 0 ? (
                  <>
                    {vehiculosComptatibles.map((item, index) => {
                      return (
                        <Vehicle
                          key={index}
                          image={item.imagen}
                          name={item.nombre}
                          brand={item.marca}
                          id_categoria={item.id_categoria}
                          plazas={item.plazas}
                          id={item.id}
                          slug={item.slug}
                        ></Vehicle>
                      );
                    })}
                  </>
                ) : (
                  <div>Compatible vehicles not specified</div>
                )}
              </div>
            </div>
          )}

          {tab === "colors" && (
            <div>
              <h2>Available Colors</h2>
              <div>
                <img style={{ width: "100%" }} src={ImageColors}></img>
              </div>
            </div>
          )}

          {tab === "shipping" && (
            <div>
              <h2>Shipping</h2>
              <p>
                General Shipping Policy The following terms apply to all
                shipments made within the Republic of Mexico. <br></br>
                <br></br>The prices published on the site do NOT include
                shipping costs within the Mexican Republic unless expressly
                indicated by any equipment or any particular promotion, the
                prices of the products can change at any time without previous
                notice, you can not combine promotions of others Means other
                than those presented on this site. <br></br>
                <br></br>The customer agrees to provide a valid address
                locatable within the Mexican Republic where the order can be
                delivered only in case of being sent home, in case of service
                occurs must have official identification to pick up your
                package. <br></br>
                <br></br>In addition if the customer provides an address that is
                not located by the parcel, any additional cost will be covered
                by the customer itself.<br></br>
                <br></br> All shipments made by us and our suppliers are
                perfectly packed, in case your order is delivered with physical
                damage by parcels, you must add a note when signing the manifest
                to the parcel service employee indicating that the Package shows
                signs of abuse (open box, hit, punched, wet, unwrapped, etc.)
                Without this annotation you receive the package accordingly.{" "}
                <br></br>
                <br></br> If the parcel service employee does not allow you to
                enter on the delivery manifest, you MUST NOT receive the
                package. <br></br>
                <br></br>If you suspect that the box delivered to you by the
                parcel service employee does not contain what you purchased,
                either by the weight or size of the package, you MUST NOT
                receive it by telling the parcel service employee the reasons
                and you must report it to us Immediately to clarify your
                request. <br></br>
                <br></br>Once the package has been received by you or someone at
                the delivery address, or service happens to have 24 hours to
                report anomalies due to physical or missing damage to your
                order, no change or claim is made after this deadline. <br></br>
                <br></br>Distribution area The distribution area of ​​our
                products is limited to the States of the Mexican Republic.{" "}
                <br></br>
                <br></br>We deliver in all locations where there is coverage
                through the parcel companies with which we have service.{" "}
                <br></br>
                <br></br>In deliveries by parcels we depend totally on the
                conditions of each of them, as well as on the weather conditions
                and risk areas of each entity. <br></br>
                <br></br>We can not process orders with the incomplete delivery
                address, nor with special agreements of other parcels.<br></br>
                <br></br> Delivery Time of Products on Order The approximate
                time of delivery of products on order is 4 to 6 weeks, dates
                remain in communication with the supplier or manufacturer of the
                products on order, however, by policies of these suppliers or
                manufacturers they can decide: Do not stock a product for its
                commercial policies or any other circumstance outside our
                company, in this case you will be notified by email so that you
                decide to change your product by another model or cancel your
                order. <br></br>
                <br></br>To order the order in a longer time to the estimate, in
                this case you will be notified by email so that you decide if
                you want to continue waiting or cancel your order. <br></br>
                <br></br>Orders paid with more than 6 weeks have a money back
                guarantee, which means that you can request the return of the
                paid. If your order includes several products on order, it will
                be sent when all your order is complete, if you need to be
                shipped separately will be charged a shipment each time a
                product is released from the warehouses.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <MainLayout margin>
        <Container>
          {loader ? (
            <Loading text="Loading product data..."></Loading>
          ) : (
            <div>
              <Row>
                <Col xs={12} md={12} lg={7}>
                  <ProductGallery></ProductGallery>
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <div className="product__info">
                    <div className="product__info__name">
                      <span>{producto.nombre}</span>
                    </div>
                    {producto.precio_oferta === null || undefined || false ? (
                      <div className="product__info__price">
                        <span className="product__info__caption">Price</span>
                        <span className="product__info__price__number">
                          ${producto.precio}
                        </span>
                      </div>
                    ) : (
                      <div className="product__info__price">
                        <span className="product__info__caption">Price</span>
                        <span
                          className="product__info__price__number"
                          style={{
                            textDecoration: "line-through",
                            color: color.textSecondary,
                            fontSize: 18,
                          }}
                        >
                          ${producto.precio}
                        </span>

                        <span
                          className="product__info__caption"
                          style={{ color: color.secondary }}
                        >
                          Special price
                        </span>
                        <span className="product__info__price__number">
                          ${producto.precio_oferta}
                        </span>
                      </div>
                    )}
                    <div className="product__info__category">
                      <span className="product__info__caption">Category</span>

                      <Badge variant="secondary">{producto.categoria}</Badge>
                    </div>
                    <div
                      className="product__info__color"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <span className="product__info__caption">
                        Color <span style={{ color: "red" }}>*</span>
                      </span>
                      <SelectPicker
                        key="color-picker-input"
                        data={colores}
                        appearance="default"
                        placeholder="Select the color"
                        cleanable={false}
                        value={colorSeleccionado}
                        onChange={(val) => setColorSeleccionado(val)}
                        searchable={false}
                        style={{ width: "100%" }}
                      />

                      <div style={{ marginTop: 5 }}>
                        (See colors info on Tab "Available Colors" below)
                      </div>
                    </div>
                    {colorSeleccionado === "12" && (
                      <div
                        className="product__info__comments"
                        style={{ marginBottom: 10 }}
                      >
                        <span
                          key="color-input"
                          style={{ marginBottom: 5 }}
                          className="product__info__caption"
                        >
                          Special color
                        </span>
                        <Input />

                        <div className="text-muted">
                          Please enter the special color
                        </div>
                      </div>
                    )}
                    <div
                      className="product__info__color"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <span className="product__info__caption">
                        Quantity <span style={{ color: "red" }}>*</span>
                      </span>
                      <SelectPicker
                        key="quantity-input"
                        data={[
                          { label: 1, value: 1 },
                          { label: 2, value: 2 },
                          { label: 3, value: 3 },
                          { label: 4, value: 4 },
                          { label: 5, value: 5 },
                          { label: 6, value: 6 },
                          { label: 7, value: 7 },
                          { label: 8, value: 8 },
                          { label: 9, value: 9 },
                          { label: 10, value: 10 },
                        ]}
                        appearance="default"
                        placeholder="Select the color"
                        value={quantity}
                        onChange={(val) => setQuantity(val)}
                        cleanable={false}
                        searchable={false}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div>
                      <span className="product__info__caption">
                        Vehicle <span style={{ color: "red" }}>*</span>
                      </span>
                      <Input
                        key="vehicle-input"
                        value={vehiculo}
                        onChange={(val) => setVehiculo(val)}
                      />
                    </div>

                    {producto.id_categoria == 31 && (
                      <>
                        {" "}
                        <div
                          className="product__info__color"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          <span className="product__info__caption">
                            Options <span style={{ color: "red" }}>*</span>
                          </span>
                          <SelectPicker
                            key="color-picker-input"
                            data={optionsTreeKickers}
                            appearance="default"
                            placeholder="Select the option"
                            cleanable={false}
                            value={optionSelected}
                            onChange={(val) => setOptionSelected(val)}
                            searchable={false}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </>
                    )}

                    <div>
                      <span
                        className="product__info__caption"
                        style={{ marginTop: 10 }}
                      >
                        Comments
                      </span>
                      <Input
                        key="comments-input"
                        value={comments}
                        onChange={(val) => setComments(val)}
                      />
                    </div>
                    <div className="product__info__addToCart">
                      <Button
                        onClick={() => _addToCart()}
                        size="lg"
                        block
                        disabled={_validateAddCart() == false ? true : false}
                        style={{
                          backgroundColor: color.accent,
                          fontWeight: "bold",
                          color: _validateAddCart()
                            ? "white"
                            : color.textDisabled,
                          fontSize: 21,
                          background: _validateAddCart()
                            ? color.accent
                            : color.mainLight,
                        }}
                      >
                        ADD TO CART
                      </Button>

                      <div
                        style={{ textAlign: "center", marginTop: "10px" }}
                        className="text-muted"
                      >
                        {_validateText()}
                      </div>
                    </div>

                    {/* <Comments comments={comments} setComments={setComments}></Comments> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProductTabs></ProductTabs>
                  <Recommended></Recommended>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </MainLayout>
    </div>
  );
};

export default ProductPage;

import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Message, Alert, SelectPicker, Uploader, Icon, List } from "rsuite";
import APICLASS from "../../../api/web";
import SectionTitle from "../../../components/web/sectionTitle";
import utvIcon from "../../../assets/img/utv-icon-accent.png";
import Loading from "../../../components/web/Loading";
import api from "../../../constants/api";
import { MdCropOriginal } from "react-icons/md";

import useCliente from "../../../hooks/useCliente";
import apiFile from "../../../constants/apiFile";
import pathFiles from "../../../constants/pathImages";
const API = new APICLASS();

const AccountPost = (props) => {
  const cliente = useCliente();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [loaderData, setLoaderData] = useState(true);
  const [loaderPosts, setLoaderPosts] = useState(true);

  const [section, setSection] = useState("list");

  const [imagen, setImagen] = useState("");
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [posts, setPosts] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState("");
  const [vehiculosPolaris, setVehiculosPolaris] = useState([]);
  const [vehiculosCanam, setVehiculosCanam] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    _getVehicles();
  }, []);

  const _getVehicles = async () => {
    const polaris = await API.getVehiculos(1);
    const canam = await API.getVehiculos(2);

    setVehiculosPolaris(polaris.data);
    setVehiculosCanam(canam.data);
    setLoaderData(false);
  };

  useEffect(() => {
    if (section === "list") {
      _getPostsCliente();
    }
  }, [section]);

  const _getPostsCliente = async () => {
    setLoaderPosts(true);
    const { data } = await API.getPostsCliente(cliente._data().id);
    if (data !== false && Array.isArray(data)) {
      setPosts(data);
    }
    setLoaderPosts(false);
  };

  const _save = async () => {
    setError("");
    if (descripcion === "") {
      setError("Please fill the caption");
    } else {
      if (descripcion.length > 250) {
        setError("The caption can not be more longer than 250 character");
      } else {
        setLoader(true);

        const request = {
          descripcion: descripcion,
          imagen_portada: imagen,
          id_vehiculo: vehiculoSeleccionado.id,
          id_marca: marcaSeleccionada,
          id_cliente: cliente._data().id,
        };

        const { data } = await API.SavePost(request);

        if (data.status !== true) {
          setError(data.errorText);
        } else {
          Alert.success("Post saved successfully.");
          setSection("list");
          setImagen("");
          setVehiculoSeleccionado("");
          setMarcaSeleccionada("");
          setDescripcion("");
        }
        setLoader(false);
      }
    }
  };

  const _delete = async (id) => {
    if (window.confirm("Are you sure you want to delete the post?")) {
      const request = {
        id_cliente: cliente._data().id,
        id_post: id,
      };
      const { data } = await API.DeltePostCliente(request);

      if (data === true) {
        _getPostsCliente();
        setPosts([]);
      }
    } else {
      Alert.success("Error, please try later");
    }
  };

  const PostListItem = (props) => {
    return (
      <div className="post-list-item">
        <div className="post-list-item__left">
          <a target="_blank" href={`${pathFiles}/${props.item.imagen_portada}`}>
            <img src={`${pathFiles}/${props.item.imagen_portada}`}></img>
          </a>

          <div>
            <p>{props.item.descripcion}</p>
            <p className="post-list-item__left__vehiculo">
              {props.item.vehiculo_nombre}, {props.item.marca_nombre}
            </p>
          </div>
        </div>
        <div className="post-list-item__right">
          <Button
            onClick={() => _delete(props.item.id)}
            style={{ color: "red" }}
            variant="link"
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  const VehicleListItem = useCallback(
    (props) => {
      return (
        <div>
          {vehiculoSeleccionado !== "" && (
            <div
              style={{
                fontSize: 24,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setVehiculoSeleccionado("")}
              >
                x
              </span>
            </div>
          )}

          <div
            className={
              vehiculoSeleccionado !== false
                ? vehiculoSeleccionado.id === props.item.id
                  ? "drawer__list-vehicles__item--active"
                  : "drawer__list-vehicles__item"
                : "drawer__list-vehicles__item"
            }
            onClick={() => setVehiculoSeleccionado(props.item)}
          >
            <img src={utvIcon}></img>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <span className="drawer__list-vehicles__item__name">
                  {props.item.nombre}
                </span>
                <div className="drawer__list-vehicles__item__info">
                  <span>{props.item.marca}</span>
                  <span>{props.item.plazas} places</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [vehiculoSeleccionado]
  );

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="animate__animated animate__fadeIn">
              {section === "list" ? (
                <div>
                  {/* =============== lista =============== */}
                  <SectionTitle
                    title={"My posts"}
                    caption={"Here you can find your post"}
                    action
                    actionText="Create new post"
                    onClick={() => setSection("create")}
                  ></SectionTitle>

                  {loaderPosts ? (
                    <Loading text="loading posts"></Loading>
                  ) : (
                    <div>
                      {posts.length > 0 ? (
                        <div>
                          {/* ====== lista post ======= */}
                          {posts.map((item, index) => {
                            return (
                              <PostListItem
                                item={item}
                                key={index}
                              ></PostListItem>
                            );
                          })}
                          {/* ====== /lista post ======= */}
                        </div>
                      ) : (
                        <div className="empty">
                          <MdCropOriginal size={60}></MdCropOriginal>
                          <span>You have not created a post yet</span>
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() => setSection("create")}
                          >
                            CREATE NEW POST
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* =============== /lista =============== */}
                </div>
              ) : (
                <div>
                  {/* =============== crear nuevo =============== */}

                  <SectionTitle
                    title={"New post"}
                    caption={"Create new post"}
                    action
                    actionText="Back to list"
                    onClick={() => setSection("list")}
                  ></SectionTitle>

                  {/* ===== image picker ===== */}

                  {imagen === "" ? (
                    <div
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <h6>Select the picture</h6>
                      <Uploader
                        style={{ marginLeft: 10 }}
                        onSuccess={(res) => {
                          if (res !== false) {
                            setImagen(res);
                          }
                        }}
                        listType="picture"
                        action={`${apiFile}`}
                        data={{
                          ot: "SavePostImage",
                          app: "web",
                        }}
                        name="img"
                      >
                        <button>
                          <Icon icon="camera-retro" size="lg" />
                        </button>
                      </Uploader>
                    </div>
                  ) : (
                    <div>
                      <h6 style={{ marginTop: 40 }}>Image</h6>
                      <div
                        style={{
                          width: "200px",
                          fontSize: 24,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setImagen("")}
                        >
                          x
                        </span>
                      </div>
                      <img
                        style={{ width: "200px" }}
                        src={`${pathFiles}${imagen}`}
                      />
                    </div>
                  )}

                  {/* ===== /image picker ===== */}

                  {/* ===== form ===== */}
                  <Form>
                    <Form.Group controlId="descripcion">
                      <h6 style={{ marginTop: 40 }}>Caption</h6>

                      <Form.Control
                        value={descripcion}
                        onChange={(val) => setDescripcion(val.target.value)}
                        type="text"
                        placeholder="Enter caption for your new post"
                      />
                    </Form.Group>
                  </Form>
                  {/* ===== /form ===== */}

                  {/* ===== marca picker ===== */}

                  <div className="form-box form-box--transparent">
                    <div style={{ marginTop: 10, marginBottom: 20 }}>
                      <span
                        style={{
                          display: "block",
                          marginBottom: 3,
                          fontWeight: "bold",
                        }}
                      >
                        Brand
                      </span>
                      <SelectPicker
                        searchable={false}
                        value={marcaSeleccionada}
                        cleanable={false}
                        onChange={(val) => setMarcaSeleccionada(val)}
                        data={[
                          {
                            label: "Can-am",
                            value: "2",
                          },
                          {
                            label: "Polaris",
                            value: "1",
                          },
                        ]}
                        style={{ width: "100%" }}
                        placeholder="Select brand"
                      />
                    </div>
                    {/* ===== /marca picker ===== */}

                    {vehiculoSeleccionado !== "" && (
                      <div className="drawer__list-vehicles">
                        <VehicleListItem
                          item={vehiculoSeleccionado}
                        ></VehicleListItem>
                      </div>
                    )}

                    {/* ===== lista de vehiculos ===== */}
                    {vehiculoSeleccionado === "" && (
                      <div>
                        {loaderData ? (
                          <Loading text="loading models"></Loading>
                        ) : (
                          <div className="drawer__list-vehicles">
                            {marcaSeleccionada !== "" && (
                              <h6 style={{ marginTop: 40 }}>
                                Select the vehicle
                              </h6>
                            )}
                            {marcaSeleccionada === "2" && (
                              <div>
                                <h2 style={{ marginTop: 0, marginBottom: 5 }}>
                                  Canam
                                </h2>
                                {vehiculosCanam.map((item, index) => {
                                  return (
                                    <VehicleListItem
                                      key={index}
                                      item={item}
                                    ></VehicleListItem>
                                  );
                                })}
                              </div>
                            )}
                            {marcaSeleccionada === "1" && (
                              <div>
                                <h2 style={{ marginTop: 10, marginBottom: 5 }}>
                                  Polaris
                                </h2>
                                {vehiculosPolaris.map((item, index) => {
                                  return (
                                    <VehicleListItem
                                      key={index}
                                      item={item}
                                    ></VehicleListItem>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {/* ===== /lista de vehiculos ====== */}

                    {error !== "" && (
                      <Message
                        type="error"
                        title="Error"
                        description={<p>{error}</p>}
                      />
                    )}

                    {descripcion !== "" &&
                    vehiculoSeleccionado !== "" &&
                    marcaSeleccionada !== "" &&
                    imagen !== "" ? (
                      <Button
                        style={{ marginTop: 20 }}
                        variant="primary"
                        disabled={loader}
                        onClick={() => _save()}
                      >
                        {loader ? "Loading…" : "SAVE"}
                      </Button>
                    ) : (
                      <Button
                        style={{ marginTop: 20 }}
                        variant="primary"
                        disabled={true}
                        onClick={() => _save()}
                      >
                        SAVE
                      </Button>
                    )}
                  </div>
                  {/* =============== /crear nuevo =============== */}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountPost;

import React, { useContext } from "react";
import StoreState from "../state/store";
import { Alert } from "rsuite";
import APICLASS from "../api/web";
const API = new APICLASS();

const useCarrito = () => {
  const { cart, setCart, impuestos, setImpuestos, cliente } =
    useContext(StoreState);

  const _addToCart = async (
    item,
    cantidad,
    portada,
    tieneOferta,
    precioCobrar,
    id_vehiculo,
    color,
    comments,
    vehiculo,
    optionSelected
  ) => {
    let itemCantidad = {
      ...item,
      cantidad: cantidad,
      portada: portada,
      tieneOferta: tieneOferta,
      precioCobrar: precioCobrar,
      id_vehiculo: id_vehiculo,
      vehiculo: vehiculo,
      precioEnvio: item.precio_envio,
      color: color,
      comments: comments,
      optionSelected: optionSelected,
    };
    let newCart = [...cart, itemCantidad];
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    Alert.success(`${item.nombre}(${cantidad}) was added to the cart`);
    const { data } = await API.getCategories();
  };

  const _getItems = () => {
    return cart;
  };

  const _getTotal = () => {
    let newTotal = 0;
    newTotal = cart.reduce(
      (newTotal, item) => newTotal + item.precioCobrar * item.cantidad,
      0
    );
    return newTotal.toFixed(2);
  };

  const _getTotalParsed = () => {
    let newTotal = 0;
    newTotal = cart.reduce(
      (newTotal, item) => newTotal + item.precioCobrar * item.cantidad,
      0
    );
    return parseFloat(newTotal);
  };

  const _getTotalShipping = () => {
    let totalShipping = 0;
    totalShipping = cart.reduce(
      (totalShipping, item) => totalShipping + item.precioEnvio * item.cantidad,
      0
    );

    return totalShipping.toFixed(2);
  };

  const _getTotalShippingParsed = () => {
    let totalShipping = 0;
    totalShipping = cart.reduce(
      (totalShipping, item) => totalShipping + item.precioEnvio * item.cantidad,
      0
    );

    return parseFloat(totalShipping);
  };

  const _getTotalOrder = () => {
    let totalItems = _getTotalParsed();
    let totalShipping = _getTotalShippingParsed();
    let impuestos = _getImpuestosParsed();
    let totalFinal = totalItems + totalShipping + impuestos;
    let totalGranFinal = totalFinal - _getTotalDescuentoResellerParsed();

    return totalGranFinal.toFixed(2);
  };

  const _getTotalOrderParsed = () => {
    let totalItems = _getTotalParsed();
    let totalShipping = _getTotalShippingParsed();
    let impuestos = _getImpuestosParsed();
    let totalFinal = totalItems + totalShipping + impuestos;
    let totalGranFinal = totalFinal - _getTotalDescuentoResellerParsed();

    return parseFloat(totalGranFinal);
  };

  const _getTotalDescuentoReseller = () => {
    if (cliente.seller == 1) {
      let totalItems = _getTotalParsed();
      let totalDescuento = totalItems * parseFloat(cliente.descuento_seller);

      return totalDescuento.toFixed(2);
    } else {
      return 0;
    }
  };

  const _getTotalDescuentoResellerParsed = () => {
    if (cliente.seller == 1) {
      let totalItems = _getTotalParsed();
      let totalDescuento = totalItems * parseFloat(cliente.descuento_seller);

      return parseFloat(totalDescuento);
    } else {
      return 0;
    }
  };

  const _agregarImpuestos = () => {
    let tasaTextas = 8.25;
    let totalPedido = _getTotalParsed();
    let totalMasImpuestos = (totalPedido * tasaTextas) / 100;

    setImpuestos(parseFloat(totalMasImpuestos));
  };

  const _quitarImpuestos = () => {
    setImpuestos(0);
  };

  const _getImpuestos = () => {
    if (impuestos > 0) {
      return impuestos.toFixed(2);
    } else {
      return 0;
    }
  };

  const _getImpuestosParsed = () => {
    if (impuestos > 0) {
      return parseFloat(impuestos);
    } else {
      return 0;
    }
  };

  const _removeFromCart = (index) => {
    cart.splice(index, 1);
    setCart([...cart]);
    localStorage.setItem("cart", JSON.stringify([...cart]));
  };

  const _cleanCart = () => {
    setCart([]);
    localStorage.setItem("cart", JSON.stringify([]));
  };

  const _getItemsCount = (item) => {
    return cart.length;
  };

  return {
    _addToCart,
    _getTotal,
    _removeFromCart,
    _getItemsCount,
    _getItems,
    _getTotalParsed,
    _getTotalShipping,
    _getTotalShippingParsed,
    _getTotalOrder,
    _getTotalOrderParsed,
    _cleanCart,
    _agregarImpuestos,
    _getImpuestos,
    _getImpuestosParsed,
    _quitarImpuestos,
    _getTotalDescuentoReseller,
    _getTotalDescuentoResellerParsed,
  };
};

export default useCarrito;

import React, { useContext, useEffect } from "react";
import useCliente from "../../../hooks/useCliente";
import StoreState from "../../../state/store";
import { SelectPicker, Alert } from "rsuite";
import { Button } from "react-bootstrap";
import useNavigation from "../../../hooks/useNavigation";
import APICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";

const API = new APICLASS();

const HeroSelectAction = () => {
  const cliente = useCliente();
  const navigation = useNavigation();

  const {
    setDrawerVehiculosToggle,
    vehiculoSeleccionado,
    setVehiculoSeleccionado,
  } = useContext(StoreState);

  const [vehiculosCliente, setVehiculosCliente] = useStateIfMounted([]);

  useEffect(() => {
    _setVehiculos();
  }, []);

  const _setVehiculos = () => {
    if (cliente._getVehiculos() !== false) {
      let vehiculos = cliente._getVehiculos();
      let vehiculosArr = [];
      vehiculos.map((item) => {
        item = { ...item, id: item.id_vehiculo };
        let vehiculosItem = {
          label: item.nombre,
          value: item,
        };

        vehiculosArr.push(vehiculosItem);
      });

      setVehiculosCliente(vehiculosArr);
    }
  };

  const _selectVehiculo = (item) => {
    setVehiculoSeleccionado(item);
    localStorage.setItem("selectedVehicle", JSON.stringify(item));
    Alert.success(`${item.nombre} selected`);
  };

  const ConCliente = (props) => {
    return (
      <div>
        {vehiculoSeleccionado !== false ? (
          <div>
            <h4>OUR SUGGESTIONS FOR</h4>
            <h1>{vehiculoSeleccionado.nombre}</h1>
            <Button
              onClick={() =>
                navigation._navigate(
                  `/vehicle/${vehiculoSeleccionado.slug}/${vehiculoSeleccionado.id}`
                )
              }
            >
              SHOP NOW FOR {vehiculoSeleccionado.nombre}
            </Button>
          </div>
        ) : (
          <div>
            {vehiculosCliente.length > 0 ? (
              <div>
                <h6>Select vehicle from your list to recieve suggestions</h6>
                <SelectPicker
                  searchable={false}
                  value={vehiculosCliente}
                  cleanable={false}
                  onChange={(val) => _selectVehiculo(val)}
                  data={vehiculosCliente}
                  style={{ width: "100%" }}
                  placeholder="Select your Vehicle"
                />
              </div>
            ) : (
              <div>
                <h6 style={{ fontSize: 19 }}>
                  To start receiving Accessories suggestions
                </h6>

                <Button
                  onClick={() => navigation._navigate("/account/my-vehicles")}
                  style={{ marginTop: 10 }}
                >
                  ADD A VEHICLE TO THE SYSTEM
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const SinCliente = (props) => {
    return (
      <div>
        {vehiculoSeleccionado !== false ? (
          <div>
            <h4>OUR SUGGESTIONS FOR</h4>
            <h1>{vehiculoSeleccionado.nombre}</h1>
            <Button
              onClick={() =>
                navigation._navigate(
                  `/vehicle/${vehiculoSeleccionado.slug}/${vehiculoSeleccionado.id}`
                )
              }
            >
              SHOP NOW FOR {vehiculoSeleccionado.nombre}
            </Button>
          </div>
        ) : (
          <div>
            <h6 style={{ fontSize: 19 }}>
              To start receiving Accessories suggestions
            </h6>

            <Button
              onClick={() => navigation._navigate("/sign-up")}
              style={{ marginTop: 10 }}
            >
              SIGN UP
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {cliente._clienteLogged() !== false ? (
        <ConCliente></ConCliente>
      ) : (
        <SinCliente></SinCliente>
      )}
    </div>
  );
};

export default HeroSelectAction;
